import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import Signup from '../views/admin/Signup.vue';
import Login from '../views/admin/Login.vue';
import User from '../views/admin/User.vue';
import Facility from '../views/admin/Facility.vue';
import CourseEdit from '../views/admin/CourseEdit.vue';
import CourseCreate from '../views/admin/CourseCreate.vue';
import CourseList from '@/views/admin/CourseList.vue';
import CourseDetails from '@/views/CourseDetail.vue';
import Audit from '@/views/admin/Audit.vue';
import Page from '@/views/Page.vue';
import Address from '@/views/admin/Address.vue';
import FacilityCalendar from '@/views/admin/FacilityCalendar.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/user',
    name: 'User',
    component: User
  },
  {
    path: '/facility',
    name: 'Faciliiy',
    component: Facility
  },
  {
    path: '/facilityCalendar/:id',
    name: 'FacilityCalendar',
    component: FacilityCalendar,
    props: true
  },

  {
    path: '/audit',
    name: 'Audit',
    component: Audit
  },
  {
    path: '/courseCreate',
    name: 'CourseCreate',
    component: CourseCreate
  },
  {
    path: '/courseList',
    name: 'CourseList',
    component: CourseList
  },
  {
    path: '/courseEdit/:id',
    name: 'CourseEdit',
    component: CourseEdit,
    props: true
  },
  {
    path: '/courses/:id',
    name: 'CourseDetails',
    component: CourseDetails,
    props: true
  },
  {
    path: '/page/:id',
    name: 'Page',
    component: Page,
    props: true
  },
  {
    path: '/address',
    name: 'Address',
    component: Address
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
