import { defineStore } from 'pinia';

import { db } from '@/firebase/config';
import { doc, getDoc } from 'firebase/firestore';

export const useConfiguraitonStore = defineStore({
  id: 'configuration',
  state: () => ({
    parameters: [],
    loading: true
  }),
  actions: {
    getConfiguration() {
      if (this.parameters.length !== 0) {
        return;
      }
      this.loading = true;
      this.parameters = [];
      try {
        getDoc(doc(db, 'config', 'parameters')).then((snapshot) => {
          this.parameters = snapshot.data();
          this.parameters.club = { displayName: 'SV RW Alfen', name: 'RWA' };
          this.parameters.title = 'SV RW Alfen - Mehr als nur Sport';
          this.parameters.defaultColor = 'cc0b1c';
          this.loading = false;
        });
      } catch (error) {
        console.log(error);
      }
    }
  }
});
