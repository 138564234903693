<template>
  <div class="columns">
    <div class="column"></div>
    <div class="column is-four-fifths">
      <article class="message box p-0 is-primary my-6 has-shadow">
        <p class="message-header">Registierung als neuer Benutzer</p>
        <div class="block p-4">
          <form class="message is-primary">
            <div class="block">
              Eine Registierung wird benötigt, um neue Angebote einzurichten und
              bestehende zu aktualisieren.
            </div>
            <div class="field is-horizontal">
              <div class="field-body">
                <!-- First name -->
                <div class="field">
                  <label class="label">Vorname </label>
                  <div class="control">
                    <input
                      class="input"
                      type="text"
                      placeholder="Vorname"
                      v-model="firstname"
                    />
                  </div>
                </div>
                <!-- Last name -->
                <div class="field">
                  <label class="label">Nachname </label>
                  <div class="control">
                    <input
                      class="input"
                      type="text"
                      placeholder="Nachname"
                      v-model="lastname"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- email -->
            <div class="field">
              <label class="label">E-Mail Adresse </label>
              <div class="control">
                <input
                  class="input is-normal"
                  type="email"
                  placeholder="email"
                  v-model="email"
                />
              </div>
            </div>
            <!-- password -->
            <div class="field">
              <label class="label">Passwort </label>
              <div class="control">
                <input
                  class="input"
                  type="password"
                  placeholder="password"
                  v-model="password"
                />
              </div>
            </div>
            <!-- password -->
            <div class="field">
              <label class="label">Bestätigen des Passwortes </label>
              <div class="control">
                <input
                  class="input"
                  type="password"
                  placeholder="repeat password"
                  v-model="password2"
                />
              </div>
            </div>
            <!-- error message -->
            <article v-if="error" class="message is-danger">
              <div class="message-body">
                {{ error }}
              </div>
            </article>
            <!-- Submit  -->
            <div class="field is-grouped is-grouped-centered">
              <p class="control">
                <a class="button is-primary" @click="handleSubmit">
                  Registrieren
                </a>
              </p>
            </div>
          </form>
        </div>
      </article>
    </div>
    <div class="column"></div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useUserStore } from '@/stores/UserStore';
import { useAuditStore } from '@/stores/AuditStore';
import { useRouter } from 'vue-router';
import { useConfiguraitonStore } from '@/stores/ConfigStore.js';

export default {
  setup() {
    // to visualize any error during from processing
    const error = ref(null);
    // get store
    const config = useConfiguraitonStore();
    // data field
    const email = ref('');
    const password = ref('');
    const password2 = ref('');
    const clubs = ref(config.parameters.Vereine);
    const selectedClub = ref(config.parameters.club);
    const firstname = ref('');
    const lastname = ref('');
    const picked = ref('');
    // store
    const user = useUserStore();
    const audit = useAuditStore();

    // activate router
    const router = useRouter();

    const handleSubmit = () => {
      // check if password is OK
      if (password.value != password2.value) {
        error.value = 'Die eingegebenen Passwörter stimmen nicht überein';
      } else if (!selectedClub.value) {
        error.value = 'Eine Anmeldung ist nur für einen Verein möglich';
      } else if (!firstname.value || !lastname.value) {
        error.value = 'Ein Name ist für die Anmeldung erforderlich';
      } else {
        // call store to manage signup
        const data = {
          club: selectedClub.value,
          firstname: firstname.value,
          lastname: lastname.value,
          email: email.value,
          password: password.value
        };
        console.log(data);
        user
          .signup(data)
          .then(() => {
            audit.setAuditEntry(
              'Benutzer registriert: ' + firstname.value + ' ' + lastname.value,
              selectedClub.value.name
            );
            error.value = '';
            router.push({ name: 'Home' });
          })
          .catch((err) => {
            console.log('Signup Error: ');
            console.dir(err);
            switch (err) {
              case 'auth/invalid-email':
                error.value = 'Ungültige E-Mail Adresse';
                break;
              case 'auth/weak-password':
                error.value = 'Das Passwort muss mindestens 6 Zeichen haben).';
                break;
              case 'auth/email-already-in-use':
                error.value = 'Diese E-Mail Adresse ist schon angemeldet.';
                break;
              default:
                error.value =
                  'Es ist ein Fehler bei der Registrierung aufgetreten';
                break;
            }
          });
      }
    };

    return {
      handleSubmit,
      email,
      password,
      password2,
      clubs,
      selectedClub,
      firstname,
      lastname,
      error,
      picked
    };
  }
};
</script>

<style>
.checkboxGroup {
  max-height: 30em;
  overflow: auto;
  display: block;
  border-style: solid;
  border-color: #4f515a;
}
</style>
