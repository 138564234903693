<template>
  <div class="block mx-4 mt-4">
    <article class="message box p-0 is-primary has-shadow">
      <div class="message-header">
        <p>Sportangebot anlegen</p>
      </div>
      <div class="block p-4">
        <!-- error message -->
        <article v-if="error" class="message is-danger">
          <div class="message-body">
            {{ error }}
          </div>
        </article>

        <!-- form to enter / edit course data -->
        <FormCourse :course="course" :uploadFile="uploadFile" />

        <!-- Create new course  -->
        <div class="field is-grouped is-grouped-centered">
          <label class="checkbox">
            <input type="checkbox" v-model="confirm" />
            Hiermit bestätige ich, dass alle Angaben im Namen des Vereins
            gemacht wurden für den ich angemeldet bin. <br />
            Weiterhin bestätige ich das ich geprüft habe, das keine Rechte
            Dritter an meinen Angaben und des Bildes besthen.
            <br />
            Alle rechtlichen Verbindlichkeiten für diesen Beitrag übernehem ich.
          </label>
        </div>
        <div class="box is-centered">
          <button @click="handleSubmit" class="button is-info is-fullwidth">
            Speichern
          </button>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
// vue imports
import { ref } from 'vue';
import { useRouter } from 'vue-router';

// firebase imports
import { db } from '@/firebase/config';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { useConfiguraitonStore } from '@/stores/ConfigStore.js';
import { useUserStore } from '@/stores/UserStore';
import { useAuditStore } from '@/stores/AuditStore';

import UploadMediafile from '@/components/uploadMediafile.vue';
import FormCourse from '@/components/formCourse.vue';

export default {
  components: { UploadMediafile, FormCourse },
  setup() {
    // to visualize any error during from processing
    const error = ref(null);
    const router = useRouter();

    // get Stores
    const config = useConfiguraitonStore();
    const user = useUserStore();
    const audit = useAuditStore();

    // course
    const course = ref({
      club: config.parameters.club,
      name: '',
      description: '',
      contact: '',
      link: '',
      tags: [],
      image: '',
      color: config.parameters.colorDefault[config.parameters.club.name],
      order: parseInt('999'),
      intern: false
    });
    course.value.schedule = [];
    config.parameters.Days.forEach((day) => {
      course.value.schedule.push({
        day: day,
        active: false,
        starttime: '',
        endtime: '',
        hint: ''
      });
    });

    const uploadFile = ref({ mediaURL: '', file: '', error: '' });
    const confirm = ref(false);

    const handleSubmit = () => {
      error.value = '';
      if (!confirm.value) {
        error.value =
          'Bitte bestätigen die Verantwortung für dieses Angebot zu übernehmen';
      } else if (!course.value.name) {
        error.value = 'Jedes Angebot benötigt einen Namen';
      } else if (!course.value.description) {
        error.value = 'Jedes Angebot benötigt eine Beschreibung';
      } else if (!course.value.contact) {
        error.value = 'Jedes Angebot benötigt eine Kontaktangabe';
      } else if (!course.value.facility) {
        error.value = 'Bittte einen Ort auswählen';
      } else if (course.value.tags.length == 0) {
        error.value = 'Jedes Angebot benötigt mindestens ein Schlagwort';
      } else if (uploadFile.value.error) {
        error.value = uploadFile.value.error;
      }
      // check Schedule
      var hasDate = false;
      var hasStarttime = false;
      var hasEndtime = false;
      if (course.value.hasOwnProperty('schedule')) {
        course.value.schedule.forEach((day) => {
          if (day.active) {
            hasDate = true;
            if (day.starttime) {
              hasStarttime = true;
            }
            if (day.endtime) {
              hasEndtime = true;
            }
          }
        });
      }
      if (!hasDate) {
        error.value =
          'Jedes Angebot benötigt mindestens einen Veranstaltungstag';
      } else if (!hasStarttime || !hasEndtime) {
        error.value = 'Jedes Angebot benötigt eine Zeitangabe';
      }
      if (!error.value) {
        course.value.image = uploadFile.value.mediaURL;
        course.value.createdByID = user.firebaseUserID;
        course.value.createdBy = user.firstname + ' ' + user.lastname;
        course.value.createdAt = serverTimestamp();
        course.value.modiefiedAt = serverTimestamp();
        console.log(course.value);
        const docRef = addDoc(
          collection(db, config.parameters.courseDB),
          course.value
        )
          .then(() => {
            error.value = '';
            audit.setAuditEntry(
              'Sportangebot angelegt: ' + course.value.name,
              course.value.club.name
            );
            router.push({ name: 'Home' });
          })
          .catch((err) => {
            error.value = err;
          });
      } else {
        console.log(error.value);
      }
    };

    return {
      error,
      course,
      confirm,
      uploadFile,
      handleSubmit
    };
  }
};
</script>

<style scoped>
.checkboxGroup {
  max-height: 30em;
  overflow: auto;
  display: block;
  border-style: solid;
  border-color: #4f515a;
  background-color: white;
}
</style>
