<template>
  <div class="privateContainer">
    <template v-for="course in courses" :key="course.id">
      <div v-if="!course.intern">
        <router-link :to="{ name: 'CourseDetails', params: { id: course.id } }">
          <div>
            <ElementView :course="course" />
          </div>
        </router-link>
      </div>
    </template>
  </div>
</template>

<script>
import ElementView from '@/components/ElementView.vue';
export default {
  components: { ElementView },
  props: ['courses'],
  setup() {
    return {};
  }
};
</script>

<style>
.privateContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 2em;
}
</style>
