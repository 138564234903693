<template>
  <div v-if="!config.loading">
    <Navbar />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Navbar from '@/views/NavigationBar.vue';
import Footer from '@/views/Footer.vue';
import { useConfiguraitonStore } from '@/stores/ConfigStore.js';
import { useUserStore } from '@/stores/UserStore';

export default {
  components: { Navbar, Footer },
  setup() {
    const config = useConfiguraitonStore();
    config.getConfiguration();
    const userStore = useUserStore();
    userStore.initializeAuthentication();

    return { config, userStore };
  }
};
</script>
