<template>
  <article class="message box is-primary has-shadow m-5 p-0">
    <div class="message-header">
      <p>{{ props.id }}</p>
    </div>
    <div class="message-body has-background-white">
      <div class="content">
        <span v-html="pageContent"></span>
      </div>
    </div>
  </article>
</template>

<script>
import { ref, computed } from 'vue';
import { db } from '@/firebase/config';
import { doc, getDoc } from 'firebase/firestore';

import { useConfiguraitonStore } from '@/stores/ConfigStore.js';
import { useAuditStore } from '@/stores/AuditStore';

export default {
  props: ['id'],
  setup(props) {
    const error = ref();
    const config = useConfiguraitonStore();
    const audit = useAuditStore();
    const content = ref();

    const pageContent = computed(() => {
      const docRef = doc(db, config.parameters.pageDB, props.id);
      getDoc(docRef)
        .then((snapshot) => {
          content.value = snapshot.data().content;
          audit.setAuditEntry('Seite ' + props.id + '2 wurde angewählt', '');
        })
        .catch((err) => {
          error.value = err;
        });

      return content.value;
    });

    return { error, pageContent, props };
  }
};
</script>

<style></style>
