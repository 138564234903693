<template>
  <div class="columns">
    <div class="column"></div>
    <div class="column is-four-fifths">
      <article class="message box p-0 my-6 is-primary my-4 has-shadow">
        <p class="message-header">Welcome</p>
        <div class="message-body">
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate
            consequatur qui facere hic quos eius, quam, fugit pariatur ipsam eos
            eveniet quis tenetur nostrum obcaecati, quia architecto illo laborum
            assumenda nesciunt possimus repellat deleniti doloribus. Animi
            similique pariatur quam ducimus?
          </p>
        </div>
      </article>
    </div>
    <div class="column"></div>
  </div>
</template>

<script>
import { useUserStore } from '@/stores/UserStore';
import { ref } from '@vue/reactivity';
export default {
  setup() {
    const user = useUserStore();
    return { user };
  }
};
</script>

<style>
</style>