import { defineStore } from 'pinia';
// Firebase Imports
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged
} from 'firebase/auth';
import { auth } from '@/firebase/config';
import { db } from '@/firebase/config';
import {
  doc,
  getDoc,
  setDoc,
  serverTimestamp,
  updateDoc,
  increment,
  Timestamp
} from 'firebase/firestore';
import { useConfiguraitonStore } from '@/stores/ConfigStore.js';

export const useUserStore = defineStore({
  id: 'user',
  state: () => ({
    user: null,
    firstname: '',
    lastname: '',
    club: '',
    email: '',
    username: '',
    role: '',
    firebaseUserID: '',
    isLoggedIn: false
  }),
  getters: {
    getUser: (state) => {
      return state.user;
    }
  },

  getters: {
    getCurrentUser: () => auth.currentUser
  },

  actions: {
    login(data) {
      console.log('User Store - action login');
      return signInWithEmailAndPassword(auth, data.email, data.password);
    },
    logout() {
      console.log('User Store - action logout');
      signOut(auth)
        .then(() => {
          console.log('User Store - logout successful');
        })
        .catch((error) => {
          console.log('User Store - logout error: ' + error);
        });
    },
    signup(data) {
      console.log('User Store - action signup');
      // get store
      const config = useConfiguraitonStore();
      const roles = config.parameters.Roles;

      return new Promise((resolve, reject) => {
        createUserWithEmailAndPassword(auth, data.email, data.password)
          .then(async (userCredentials) => {
            // Signed in
            this.user = userCredentials.user;
            // Create recored with user details
            setDoc(doc(db, 'userCollection', userCredentials.user.uid), {
              club: data.club,
              firstname: data.firstname,
              lastname: data.lastname,
              email: data.email,
              firebaseUserID: userCredentials.user.uid,
              role: roles[0],
              createdAt: serverTimestamp()
            });
            resolve(userCredentials);
          })
          .catch((err) => {
            console.log(
              'User Store - signup error: ' + err.code + ' / ' + err.message
            );
            reject(err.code);
          });
      });
    },
    initializeAuthentication() {
      const user = this.getCurrentUser;

      if (user) {
        this.user = user;
        this.isLoggedIn = true;
      } else {
        this.user = null;
        this.isLoggedIn = false;
      }

      onAuthStateChanged(auth, (user) => {
        if (user) {
          console.log('User Logged in: ' + user.email);
          this.user = user;
          this.isLoggedIn = true;
          const docRef = doc(db, 'userCollection', user.uid);
          // increase usage counter
          updateDoc(docRef, {
            logins: increment(1),
            lastLogin: serverTimestamp()
          }).catch((err) => {
            console.log('setUser increment user count error : ' + err.message);
          });
          // read user data
          getDoc(docRef)
            .then((snapshot) => {
              this.firstname = snapshot.data().firstname;
              this.lastname = snapshot.data().lastname;
              this.email = snapshot.data().email;
              this.role = snapshot.data().role;
              this.club = snapshot.data().club;
              this.firebaseUserID = snapshot.data().firebaseUserID;
            })
            .catch((err) => {
              this.user = null;
              this.isLoggedIn = false;
            });
        } else {
          this.user = null;
          this.isLoggedIn = false;
          this.firstname = '';
          this.lastname = '';
          this.email = '';
          this.role = '';
          this.club = '';
          this.firebaseUserID = '';
        }
      });
    }
  }
});
