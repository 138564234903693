import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

// firebase imports for auth
import { auth } from '@/firebase/config';
import {
    onAuthStateChanged,
} from 'firebase/auth'

import App from './App.vue'
import router from './router'

// Import Bulma
require('@/assets/main.scss');
import './../node_modules/bulma/css/bulma.css'

// Prepare Pinia 
const pinia = createPinia();

let app;

onAuthStateChanged(auth, () => {
    if (!app) {
        app = createApp(App);

        app.use(pinia);
        app.use(router);

        app.component('QuillEditor', QuillEditor)

        app.mount("#app");
    }
});