<template>
  <!-- headline -->
  <div v-if="uploadMedia.file.name" class="card">
    <header class="card-header">
      <p class="card-header-title">
        {{ uploadMedia.file.name }}
      </p>
    </header>
    <!-- during upload -->
    <div v-if="uploadInProgress">
      <div class="card-content">
        <progress
          class="progress is-small is-success"
          :value="progressValue"
          max="100"
        >
          {{ progressValue }}
        </progress>
      </div>
    </div>
    <div v-if="uploadMedia.mediaURL" class="card-image">
      <figure class="image">
        <img :src="uploadMedia.mediaURL" alt="" />
      </figure>
      <div class="card-content is-overlay">
        <span
          v-for="category in uploadMedia.categories"
          :key="category"
          class="tag is-primary m-1"
          >{{ category }}</span
        >
        <br />
        <span
          v-for="section in uploadMedia.sections"
          :key="section"
          class="tag is-info m-1"
          >{{ section }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

import {
  ref as storageRef,
  uploadBytesResumable,
  getDownloadURL
} from 'firebase/storage';
import { storage } from '@/firebase/config';
import { useUserStore } from '@/stores/UserStore';
import CourseEdit from '@/views/admin/CourseEdit.vue';

export default {
  props: {
    uploadMedia: Object,
    course: Object
  },
  setup(props) {
    // to visualize any error during from processing
    const error = ref(null);
    const user = useUserStore();

    const uploadInProgress = ref(true);
    const currentStatus = ref('');
    const uploadMedia = props.uploadMedia;
    const course = props.course;
    const progressValue = ref(0);

    if (uploadMedia.file) {
      const metadata = {
        contentType: uploadMedia.file.type
      };
      const storageReference = storageRef(
        storage,
        'media/' +
          course.club.name +
          '/' +
          course.name +
          '/' +
          uploadMedia.file.name
      );
      getDownloadURL(storageReference)
        .then((url) => {
          uploadMedia.file = '';
          uploadMedia.mediaURL = '';
          uploadMedia.error =
            'Diese Datei ist schon einem anderen Artikel zugeordnet';
        })
        .catch((err) => {
          console.log(err);
          currentStatus.value = '';
          const uploadTask = uploadBytesResumable(
            storageReference,
            uploadMedia.file,
            metadata
          );

          // Listen for state changes, errors, and completion of the upload.
          uploadTask.on(
            'state_changed',
            (snapshot) => {
              progressValue.value =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            },
            (error) => {
              // A full list of error codes is available at
              // https://firebase.google.com/docs/storage/web/handle-errors
              console.log(error);

              switch (error.code) {
                case 'storage/unauthorized':
                  currentStatus.value =
                    'Keine Berechtigung um Dateien hochzulagen';
                  break;
                default:
                  currentStatus.value = error.message;
                  break;
              }
            },
            () => {
              // Upload completed successfully, now we can get the download URL
              getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                uploadMedia.mediaURL = downloadURL;
                uploadMedia.error = '';
                uploadInProgress.value = false;
              });
            }
          );
        });

      uploadMedia.error = currentStatus.value;
    }
    return {
      error,
      progressValue,
      uploadInProgress,
      currentStatus
    };
  }
};
</script>

<style></style>
