import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useConfiguraitonStore } from '@/stores/ConfigStore.js';
import { useUserStore } from './UserStore';
import { db } from '@/firebase/config';
import { collection, getDocs, where, query, orderBy } from 'firebase/firestore';

export const useFacilityStore = defineStore('facilities', () => {
  const facilities = ref([]);
  const loading = ref(true);

  const config = useConfiguraitonStore();
  const user = useUserStore();

  console.dir(user);
  const q = query(
    collection(db, config.parameters.facilityDB),
    orderBy('name')
  );

  getDocs(q)
    .then((snapshot) => {
      snapshot.docs.forEach((doc) => {
        facilities.value.push({ ...doc.data(), id: doc.id });
      });
      loading.value = false;
    })
    .catch((err) => {
      console.log(err);
    });

  function getName(id) {
    console.log(id);
    return facilities.value.find((facility) => facility.id === id).name;
  }

  return { facilities, loading, getName };
});
