<template>
  <footer class="footer has-shadow py-4 px-4">
    <div class="content has-text-centered has-text-white">
      <p class="has-text-white">Übersicht der Sportangebote des SV RW Alfen</p>
      <p class="is-size-7">Version dieser Seite {{ appVersion }}</p>
    </div>
  </footer>
</template>

<script>
import { version } from '../../package';

export default {
  data: () => ({
    appVersion: version
  })
};
</script>

<style></style>
