<template>
  <div class="block mx-4 mt-4">
    <article class="message box p-0 is-primary has-shadow">
      <p class="message-header">Angemeldete Benutzer</p>
      <div class="block p-4">
        <!-- error message -->
        <article v-if="error" class="message is-danger">
          <div class="message-body">
            {{ error }}
          </div>
        </article>

        <ag-grid-vue
          v-if="dataReady"
          class="ag-theme-alpine"
          style="width: 100%; height: 30em"
          :columnDefs="columns"
          :rowData="users"
          :defaultColDef="defaultColDef"
          autoSizeStrategy="fitProvidedWidth"
          rowSelection="single"
          animation="true"
          @row-selected="onRowSelected"
        ></ag-grid-vue>
      </div>
    </article>
    <article
      v-if="selectedUser"
      class="message box p-0 is-primary my-6 has-shadow"
    >
      <p class="message-header">Benutzer bearbeiten</p>
      <div class="block p-4">
        <form class="message is-primary">
          <!-- Firstname -->
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Vorname</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control is-expanded">
                  <input
                    class="input"
                    type="text"
                    placeholder="Firstname"
                    v-model="selectedUserData.firstname"
                  />
                </p>
              </div>
            </div>
          </div>
          <!-- Lastname -->
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Nachname</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control is-expanded">
                  <input
                    class="input"
                    type="text"
                    placeholder="Lastname"
                    v-model="selectedUserData.lastname"
                  />
                </p>
              </div>
            </div>
          </div>
          <!-- email -->
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">E-Mail</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control is-expanded">
                  <input
                    class="input"
                    type="text"
                    placeholder="email"
                    v-model="selectedUserData.email"
                    disabled
                  />
                </p>
              </div>
            </div>
          </div>
          <!-- role -->
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Rolle:</label>
            </div>

            <div class="field-body">
              <div
                class="field checkboxGroup p-3 has-background-white"
                style="
                  border-style: solid;
                  border-color: #babfc7;
                  border-width: 1px;
                "
              >
                <div v-for="role in config.parameters.Roles" :key="role.name">
                  <label class="checkbox">
                    <input
                      :id="role"
                      type="radio"
                      :value="role"
                      v-model="selectedRole"
                    />
                    {{ role.displayName }}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <!-- error message -->
          <article v-if="error" class="message is-danger">
            <div class="message-body">
              {{ error }}
            </div>
          </article>
          <!-- Submit  -->
          <div class="field is-grouped is-grouped-centered">
            <p class="control">
              <a class="button is-info" @click="handleSubmit"> Sichern </a>
            </p>
          </div>
        </form>
      </div>
    </article>
  </div>
</template>

<script setup>
// vue imports
import { ref } from 'vue';

// Grid imports
import { AgGridVue } from 'ag-grid-vue3';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import RenderData from '@/components/RenderData.vue';

// firebase imports
import { db } from '@/firebase/config';
import {
  collection,
  query,
  where,
  doc,
  onSnapshot,
  updateDoc,
  serverTimestamp
} from 'firebase/firestore';

// store imports
import { useConfiguraitonStore } from '@/stores/ConfigStore.js';

// to visualize any error during from processing
const error = ref(null);
// get stores
const config = useConfiguraitonStore();
// All users read from firebase
const users = ref([]);
const dataReady = ref(false);

// Current user for update
const selectedRow = ref('');
const selectedUser = ref('');
const selectedUserData = ref('');
const selectedRole = ref('');

// initialize grid view

const defaultColDef = ref({
  editable: false,
  sortable: true,
  filter: true,
  resizable: true,
  flex: 1
});

const columns = ref([
  {
    headerName: 'name',
    field: 'firstname',
    cellRenderer: RenderData,
    cellRendererParams: {
      type: 'name'
    }
  },
  {
    headerName: 'E-Mailadresse',
    field: 'email'
  },
  {
    headerName: 'Registriert seit',
    field: 'createdAt',
    cellRenderer: RenderData,
    cellRendererParams: {
      type: 'date',
      instruction: 'none'
    }
  },
  {
    headerName: 'Zuletzt eingeloggt',
    field: 'lastLogin',
    cellRenderer: RenderData,
    cellRendererParams: {
      type: 'date',
      instruction: 'none'
    }
  },
  {
    headerName: 'Anzahl logins',
    field: 'logins'
  },
  {
    headerName: 'Rolle',
    field: 'role.displayName'
  }
]);

// collection ref for users
const q = query(
  collection(db, 'userCollection'),
  where('club.name', '==', config.parameters.club.name)
);

// Read user data

onSnapshot(
  q,
  (snapshot) => {
    users.value = [];
    snapshot.docs.forEach((doc) => {
      users.value.push({ ...doc.data(), id: doc.id });
    });
    dataReady.value = true;
  },
  (err) => {
    console.log(err);
    error.value = err.message;
  }
);

function onRowSelected(event) {
  console.log('row selected');
  console.log(event);
  if (event.api.getSelectedRows().length > 0) {
    selectedRow.value = event.api.getSelectedRows()[0];
    selectedUserData.value = selectedRow.value;
    selectedUser.value = selectedRow.value.id;
    selectedRole.value = selectedRow.value.role;
  } else {
    selectedRow.value = '';
  }
}

// Update user data
const handleSubmit = () => {
  let docRef = doc(db, 'userCollection', selectedUserData.value.id);

  updateDoc(docRef, {
    firstname: selectedUserData.value.firstname,
    lastname: selectedUserData.value.lastname,
    role: selectedRole.value,
    updateAt: serverTimestamp()
  }).then(() => {
    selectedUser.value = '';
  });
};
</script>

<style scoped>
#my-table .table {
  background-color: whitesmoke;
}

.checkboxGroup {
  max-height: 30em;
  overflow: auto;
  display: block;
  border-style: solid;
  border-color: #4f515a;
}
</style>
