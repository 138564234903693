import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { useConfiguraitonStore } from '@/stores/ConfigStore.js';
import { useUserStore } from '@/stores/UserStore';
import { db } from '@/firebase/config';
import { collection, query, addDoc, serverTimestamp } from 'firebase/firestore';

export const useAuditStore = defineStore('audit', () => {
  const loading = ref(true);
  const config = useConfiguraitonStore();
  const user = useUserStore();

  // getters
  const getAudit = computed(() => {
    return courses;
  });

  // Actions
  function setAuditEntry(auditEntry, clubName) {
    const colRef = query(collection(db, config.parameters.auditDB));
    console.log('audit: ' + auditEntry);
    let writeName = '-';
    if (user) {
      writeName = user.firstname + ' ' + user.lastname;
    }
    addDoc(colRef, {
      name: writeName,
      time: serverTimestamp(),
      content: auditEntry,
      club: clubName
    }).catch((err) => {
      console.log(err);
    });
  }

  return {
    getAudit,
    setAuditEntry,
    loading
  };
});
