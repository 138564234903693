<template>
  <div>
    <h1>Address search Google</h1>
    <div class="box">
      <div id="map"></div>
      <div id="results"></div>
      <input type="text" v-model="searchAddressQuery" @input="getAddressSearchResults"
        placeholder="Search for a city or state" class="field" />
      <ul v-if="searchAddressResults">
        <p class="py-2" v-if="addressSearchError">
          Sorry, something went wrong, please try again.
        </p>
        <p class="py-2" v-if="!addressSearchError && searchAddressResults.length === 0">
          No results match your query, try a different term.
        </p>
        <template v-else>
          <li v-for="searchAddressResult in searchAddressResults" :key="searchAddressResult.id"
            class="py-2 cursor-pointer">
            {{ searchAddressResult.name }}, {{ searchAddressResult.address }}
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import axios from 'axios';

const googleAPIKey = 'AIzaSyBDssbV553nQb8zOKM4Zmm9BaZYGeSF-LQ';
const searchQuery = ref('');
const queryTimeout = ref(null);
const searchResults = ref(null);
const searchError = ref(null);

const searchAddressQuery = ref('');
const searchAddressResults = ref(null);
const addressSearchError = ref(null);

// Google
const getAddressSearchResults = () => {
  console.log("getAddressSearchResults")
  clearTimeout(queryTimeout.value);
  queryTimeout.value = setTimeout(async () => {
    console.log("getAddressSearchResults setTimeout")

    var requestNearBySearch = {
      location: new google.maps.LatLng(51.65, 8.76),
      keyword: searchAddressQuery.value,
      radius: 8000,
    };
    var container = document.getElementById('results');
    var service = new google.maps.places.PlacesService(container);
    searchAddressResults.value = [];
    service.nearbySearch(requestNearBySearch, callbackNearBySeach);

    function callbackNearBySeach(resultsNearBySearch, status) {
      console.log("Callback nearbySearch");
      console.dir(resultsNearBySearch);
      if (status == google.maps.places.PlacesServiceStatus.OK) {
        resultsNearBySearch.forEach((result) => {
          searchAddressResults.value.push({ name: result.name, address: result.vicinity });

        })
      }
    }
  }, 1000);
};
</script>

<style lang="scss" scoped>

</style>