<template>
  <div class="flex-vert">
    <div
      class="flex-item-raster"
      v-for="timestamp in timestamps"
      :key="timestamp + indexDay"
    ></div>
    <div
      class="flex-item"
      v-for="course in courses"
      :key="course.day + course.id"
    >
      <router-link :to="{ name: 'CourseEdit', params: { id: course.id } }">
        <div
          :style="[
            baseStyles,
            getIndividualStyles(course, indexDay, startOfDay)
          ]"
        >
          <div class="is-size-6 has-text-weight-semibold">
            <p class="is-size-7">{{ course.name }}</p>
          </div>
          <div class="is-size-7">
            {{ course.starttime }} -
            {{ course.endtime }}
          </div>
          <div v-if="course.hint" class="is-size-7">
            {{ course.hint }}
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
  courseList: Array,
  day: String,
  indexDay: Number,
  timestamps: Array,
  startOfDay: String,
  raster: Number,
  rasterHightInEm: Number
});

const courses = ref([]);
let localCourses = [];

console.log('CalenderElementDay: ' + props.day);
console.log('Number of courses: ' + props.courseList.length);

if (props.courseList.length > 0) {
  // calulate hight and top for positioning
  props.courseList.forEach((course) => {
    localCourses.push({
      name: course.name,
      id: course.id,
      starttime: course.schedule[props.indexDay].starttime,
      endtime: course.schedule[props.indexDay].endtime,
      hint: course.schedule[props.indexDay].hint,
      color: course.color,
      top: position(
        diff_minutes(
          props.startOfDay,
          course.schedule[props.indexDay].starttime
        )
      ),
      height: position(
        diff_minutes(
          course.schedule[props.indexDay].starttime,
          course.schedule[props.indexDay].endtime
        )
      ),
      position: 1,
      overlapps: 0,
      calendarInitialized: false
    });
  });

  // sort by starttime
  localCourses = localCourses.sort(function (a, b) {
    if (a.top === b.top) {
      // Price is only important when cities are the same
      return b.height - a.height;
    }
    return a.top > b.top ? 1 : -1;
  });

  // check overlapps
  localCourses.forEach((actualCourse) => {
    if (!actualCourse.calendarInitialized) {
      let from = actualCourse.top;
      let to = actualCourse.top + actualCourse.height;
      console.log('check overlapps for: ' + actualCourse.name);
      localCourses.forEach((courseOfList) => {
        if (actualCourse.id != courseOfList.id) {
          let fromCourse = courseOfList.top;
          let toCourse = courseOfList.top + courseOfList.height;
          let overlap = false;
          console.log('with ' + courseOfList.name);
          if (from > fromCourse && to > toCourse && from < toCourse) {
            overlap = 1;
          }
          if (from < fromCourse && to <= toCourse && to > fromCourse) {
            overlap = 2;
          }
          if (from == fromCourse) {
            overlap = 3;
          }
          if (from < fromCourse && to > toCourse) {
            overlap = 5;
          }
          if (from >= fromCourse && to <= toCourse) {
            overlap = 6;
          }
          if (overlap) {
            console.log('overlap reason: ' + overlap);
            // Check overlap reason, move overlapping course to right
            if (overlap === 2 || overlap === 5 || overlap === 6) {
              // when on same position move to right
              if (actualCourse.position === courseOfList.position) {
                console.log('move ' + courseOfList.name + ' to right');
                courseOfList.position += 1;
                // actualCourse.overlapps += 1;
              }
            }
            if (overlap === 3) {
              if (actualCourse.position === courseOfList.position) {
                console.log('move ' + courseOfList.name + ' to right');
                courseOfList.position += 1;
              }
              if (to > toCourse) {
                actualCourse.overlapps += 1;
              }
            }
            if (overlap === 1) {
              if (actualCourse.position != courseOfList.position) {
                actualCourse.overlapps += 1;
              }
            }
            if (overlap === 6) {
              if (actualCourse.position != courseOfList.position) {
                actualCourse.overlapps += 1;
              }
            }
          } else {
            console.log('no overlap found');
          }
        }
      });
      actualCourse.calendarInitialized = true;
    }
  });
  courses.value = localCourses;
} else {
  courses.value = [];
}

// styles for course item
const baseStyles = ref({
  'z-index': '1',
  border: 'thin solid lightgray',
  position: 'absolute',
  'text-align': 'center',
  overflow: 'hidden',
  'line-height': 'normal',
  'border-radius': '5px'
});

// get course individual styles
function getIndividualStyles(course) {
  return {
    color: getTextColor(getColor(course)),
    background: getColor(course),
    height: course.height + 'em',
    top: course.top + 'em',
    width: 100 / (course.overlapps + 1) + '%',
    left: (100 / (course.overlapps + 1)) * (course.position - 1) + '%'
  };
}

// calculate duration
function diff_minutes(starttime, endtime) {
  var dt1 = new Date('2023-01-01T' + starttime);
  var dt2 = new Date('2023-01-01T' + endtime);

  var diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60;
  return Math.abs(Math.round(diff));
}

// calculate absolute and high position of course
function position(duration) {
  return (duration / props.raster) * props.rasterHightInEm;
}

// get color set in course data, but return default if not set
function getColor(course) {
  if (course.hasOwnProperty('color')) return course.color;
  else return '#d3d3d3';
}

// calculate text color
function getRGB(c) {
  return parseInt(c, 16) || c;
}
function getsRGB(c) {
  return getRGB(c) / 255 <= 0.03928
    ? getRGB(c) / 255 / 12.92
    : Math.pow((getRGB(c) / 255 + 0.055) / 1.055, 2.4);
}
function getLuminance(hexColor) {
  return (
    0.2126 * getsRGB(hexColor.substr(1, 2)) +
    0.7152 * getsRGB(hexColor.substr(3, 2)) +
    0.0722 * getsRGB(hexColor.substr(-2))
  );
}
function getContrast(f, b) {
  const L1 = getLuminance(f);
  const L2 = getLuminance(b);
  return (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05);
}
function getTextColor(bgColor) {
  const whiteContrast = getContrast(bgColor, '#ffffff');
  const blackContrast = getContrast(bgColor, '#000000');

  return whiteContrast > blackContrast ? '#ffffff' : '#000000';
}
</script>

<style scoped>
.flex-vert {
  display: flex;
  flex-direction: column;
  position: relative;
}

.flex-item-raster {
  display: flex;
  flex-basis: 3em;
  border: solid;
  border-color: ghostwhite;
  border-width: thin;
  width: 100%;
}

.flex-item {
  display: flex;
}
</style>
