<template>
  <div class="is-centered m-4">
    <div @click="$router.go(-1)" class="button is-info is-fullwidth">
      Zurück
    </div>
  </div>

  <Transition>
    <article v-if="course" class="message box is-primary has-shadow m-5 p-0">
      <div class="message-header">
        <p class="is-size-4 is-family-secondary">Sportangebot</p>
        <button
          v-if="user.firebaseUserID == course.createdByID"
          class="button is-rounded is-small"
          @click="editCourse"
        >
          <i class="material-icons">edit</i>
        </button>
      </div>
      <div class="message-body has-background-white">
        <div class="tile" v-if="course">
          <div class="tile is-ancestor">
            <div class="tile is-vertical mx-2">
              <!-- Kursname -->
              <article v-if="course.name" class="tile is-child tb-section m-3">
                <h2 class="title">{{ course.name }}</h2>
              </article>
              <!-- Beschreibung -->
              <article
                v-if="course.description"
                class="tile is-child tb-section"
              >
                <label class="label">Beschreibung:</label>
                <span class="content" v-html="course.description"></span>
              </article>
              <!-- Verein -->
              <article v-if="course.club" class="tile is-child tb-section">
                <label class="label">Anbietender Verein:</label>
                <p>
                  {{ course.club.displayName }}
                </p>
              </article>
              <!-- Veranstungsort -->
              <article v-if="course.facility" class="tile is-child tb-section">
                <label class="label">Veranstaltungsort:</label>
                <p>
                  {{ course.facility.name }}, {{ course.facility.address }} in
                  {{ course.facility.city }}
                </p>
              </article>
              <!-- Kontaktinformationen -->
              <article v-if="course.contact" class="tile is-child tb-section">
                <label class="label">Kontaktinformation:</label>
                <p>
                  {{ course.contact }}
                </p>
              </article>
              <!-- Link -->
              <article v-if="course.link" class="tile is-child tb-section">
                <label class="label">Link für weitere Informationen:</label>
                <p>
                  {{ course.link }}
                  <a
                    class="button is-small"
                    :href="course.link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i class="material-icons is-size-5">link</i>
                  </a>
                </p>
                <span class="help is-gray"
                  >Der Gemeindesportverband Borchen übernimmt keine Haftung für
                  den Link</span
                >
              </article>
              <!-- Veranstaltungstage -->
              <article v-if="course.schedule" class="tile is-child tb-section">
                <label class="label">Veranstaltungstage:</label>
                <span v-for="day in course.schedule" :key="day">
                  <div v-if="day.active">
                    {{ day.day }}s um {{ day.starttime }} bis
                    {{ day.endtime }} Uhr
                  </div>
                </span>
              </article>
              <!-- Uhrzeit -->
              <article v-if="course.starttime" class="tile is-child tb-section">
                <label class="label">Uhrzeit:</label>
                <p>{{ course.starttime }} Uhr</p>
              </article>
            </div>
            <div class="tile is-vertical mx-2">
              <!-- Bild -->
              <div v-if="course.image" class="tile is-child tb-section">
                <figure class="image p-3">
                  <img :src="course.image" />
                </figure>
              </div>
              <!-- Schlagwörter -->
              <article v-if="course.tags" class="tile is-child tb-section">
                <label class="label"
                  >Schlagwörter für dieses Sportangebot:</label
                >
                <span
                  v-for="tag in course.tags"
                  :key="tag"
                  class="tag has-background-color-tags has-text-dark m-1"
                  >{{ tag }}</span
                >
              </article>
            </div>
          </div>
        </div>
      </div>
    </article>
  </Transition>

  <div class="is-centered m-4">
    <div @click="$router.go(-1)" class="button is-info is-fullwidth">
      Zurück
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

import { db } from '@/firebase/config';
import {
  doc,
  getDoc,
  updateDoc,
  increment,
  serverTimestamp
} from 'firebase/firestore';

import { useConfiguraitonStore } from '@/stores/ConfigStore.js';
import { useUserStore } from '@/stores/UserStore';

export default {
  props: ['id'],
  setup(props) {
    const error = ref();
    const course = ref();
    const config = useConfiguraitonStore();
    const user = useUserStore();
    const router = useRouter();

    const docRef = doc(db, config.parameters.courseDB, props.id);
    getDoc(docRef)
      .then((snapshot) => {
        course.value = { ...snapshot.data() };
      })
      .catch((err) => {
        error.value = err;
      });

    // increase usage counter
    updateDoc(docRef, {
      views: increment(1),
      lastView: serverTimestamp()
    }).catch((err) => {
      console.log('usage counter update error: ' + err.message);
    });

    const editCourse = () => {
      router.push({ name: 'CourseEdit', params: { id: props.id } });
    };

    return { error, course, user, editCourse };
  }
};
</script>

<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.tb-section {
  border-style: solid;
  border-color: #d5cec1;
  background-color: #f4f4f5;
  border-radius: 0.5rem;
  padding: 0.25rem;
}
</style>
