<template>
  <div class="block mx-4 mt-4">
    <!-- error message -->
    <article v-if="error" class="message is-danger">
      <div class="message-body">
        {{ error }}
      </div>
    </article>
    <article class="message box p-0 is-primary has-shadow">
      <div class="message-header">
        <p>Neue Sportstätte anlegen</p>
        <button
          class="button is-rounded is-small"
          v-if="showCreate == true"
          @click="showCreate = false"
        >
          <i class="material-icons">close</i>
        </button>
        <button
          class="button is-rounded is-small"
          v-if="showCreate == false"
          @click="showCreate = true"
        >
          <i class="material-icons">keyboard_arrow_down</i>
        </button>
      </div>
      <div v-if="showCreate == true" class="block p-4">
        <form class="message is-primary">
          <!-- Name der Sportstätte name -->
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Name:</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control is-expanded">
                  <div
                    class="dropdown is-expanded"
                    :class="dropdownIsActive ? 'is-active' : ''"
                  >
                    <div class="dropdown-trigger">
                      <input
                        class="input is-expanded"
                        type="text"
                        placeholder="Name der Sportstätte"
                        v-model="newFacility.name"
                        @input="getAddressSearchResults"
                      />
                    </div>
                    <div id="results"></div>
                    <div v-if="searchAddressResults" class="dropdown-menu">
                      <div class="dropdown-content">
                        <a
                          v-for="searchAddressResult in searchAddressResults"
                          :key="searchAddressResult.id"
                          class="dropdown-item"
                          @click="selectSearchResult(searchAddressResult)"
                        >
                          {{ searchAddressResult.name }},
                          {{ searchAddressResult.address }}
                        </a>
                        <hr class="dropdown-divider" />
                        <a
                          href="#"
                          class="dropdown-item"
                          @click="dropdownIsActive = false"
                        >
                          Suchliste löschen
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Adresse der Sportstätte -->
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Adresse:</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control is-expanded">
                  <input
                    class="input"
                    type="text"
                    placeholder="Straße und Hausnummer der Sportstätte"
                    v-model="newFacility.address"
                  />
                </p>
              </div>
            </div>
          </div>
          <!-- Ortsteil -->
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Ortsteil:</label>
            </div>

            <div class="field-body">
              <div
                class="field checkboxGroup p-3 has-background-white"
                style="
                  border-style: solid;
                  border-color: #babfc7;
                  border-width: 1px;
                "
              >
                <div v-for="city in cities" :key="city">
                  <label class="checkbox">
                    <input
                      :id="city"
                      type="radio"
                      :value="city"
                      v-model="newFacility.city"
                    />
                    {{ city }}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <!-- Create new Facility  -->
          <div class="field is-grouped is-grouped-centered">
            <p class="control">
              <a class="button is-info" @click="handleNewFacility">
                Speichern der neuen Sportstätte
              </a>
            </p>
          </div>
        </form>
      </div>
    </article>
    <article class="message box p-0 is-primary has-shadow">
      <p class="message-header">Sportstätten</p>
      <div v-if="dataReady" class="block p-4">
        <ag-grid-vue
          class="ag-theme-alpine"
          style="width: 100%; height: 30em"
          :columnDefs="columns"
          :rowData="facilities"
          :gridOptions="gridOptions"
          :defaultColDef="defaultColDef"
          rowSelection="single"
          animation="true"
          @row-selected="onRowSelected"
        ></ag-grid-vue>
      </div>
      <!-- action bar -->
      <div class="block px-4 mx-4">
        <div
          class="columns is-4 px-4 has-background-white mb-4"
          style="
            height: 4rem;
            border-style: solid;
            border-color: #babfc7;
            border-width: 1px;
          "
          v-if="selectedRow"
        >
          <template v-if="selectedRow.club.name === user.club.name">
            <div class="column is-half has-background-light m-2">
              <span class="is-size-6">
                {{ selectedRow.name }}
              </span>
            </div>
            <div class="column">
              <button
                class="button is-success"
                @click="showEditFacility = true"
              >
                Sportstätte bearbeiten
              </button>
            </div>
            <div class="column">
              <router-link
                v-if="selectedRow"
                :to="{
                  name: 'FacilityCalendar',
                  params: { id: selectedRow.id }
                }"
              >
                <button class="button is-info">Belegungsplan anzeigen</button>
              </router-link>
            </div>
          </template>
        </div>
        <br />
      </div>
    </article>

    <article
      v-if="showEditFacility"
      class="message box p-0 is-primary my-6 has-shadow"
    >
      <div class="message-header">
        <p>Bearbeiten einer Sportstätte</p>
        <button
          class="button is-rounded is-small"
          @click="showEditFacility = false"
        >
          <i class="material-icons">close</i>
        </button>
      </div>
      <div class="block p-4">
        <form class="message is-primary">
          <!-- Facility name -->
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Name:</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control is-expanded">
                  <input
                    class="input"
                    type="text"
                    placeholder="Name der Sportstätte"
                    v-model="selectedFacilityData.name"
                  />
                </p>
              </div>
            </div>
          </div>
          <!-- Text  -->
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Adresse:</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control is-expanded">
                  <input
                    class="input"
                    type="text"
                    placeholder="Straße und Hausnummer"
                    v-model="selectedFacilityData.address"
                  />
                </p>
              </div>
            </div>
          </div>
          <!-- Ortsteil -->
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Ortsteil:</label>
            </div>

            <div class="field-body">
              <div
                class="field checkboxGroup p-3 has-background-white"
                style="
                  border-style: solid;
                  border-color: #babfc7;
                  border-width: 1px;
                "
              >
                <div v-for="city in cities" :key="city">
                  <label class="checkbox">
                    <input
                      :id="city"
                      type="radio"
                      :value="city"
                      v-model="selectedFacilityData.city"
                      :checked="city == selectedFacilityData.city"
                    />

                    {{ city }}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <!-- Submit  -->
          <div class="field is-grouped is-grouped-centered">
            <p class="control">
              <a class="button is-info" @click="handleSubmit">
                Änderung speichern
              </a>
            </p>
          </div>
        </form>
      </div>
    </article>
  </div>
</template>

<script setup>
// vue imports
import { ref } from 'vue';

// Grid imports
import { AgGridVue } from 'ag-grid-vue3';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

// firebase imports
import { db } from '@/firebase/config';
import {
  collection,
  doc,
  query,
  onSnapshot,
  updateDoc,
  deleteDoc,
  addDoc,
  orderBy
} from 'firebase/firestore';
import { useConfiguraitonStore } from '@/stores/ConfigStore.js';
import { useAuditStore } from '@/stores/AuditStore';
import { useUserStore } from '@/stores/UserStore';
import { useRouter } from 'vue-router';

// to visualize any error during from processing
const error = ref(null);
const router = useRouter();

// get Stores
const config = useConfiguraitonStore();
const audit = useAuditStore();
const user = useUserStore();

// All Facilities read from firebase
const facilities = ref([]);
const dataReady = ref(false);
const showEditFacility = ref(false);
const selectedFacility = ref('');
const selectedFacilityData = ref('');
const cities = ref(config.parameters.Cities);
const showCreate = ref(false);

// Address search
const queryTimeout = ref(null);
const searchAddressQuery = ref('');
const searchAddressResults = ref(null);
const addressSearchError = ref(null);
const dropdownIsActive = ref(false);

// New facility
const newFacility = ref('');
newFacility.value = {
  name: '',
  address: '',
  city: ''
};

// Grid definitions
const selectedRow = ref(null);
var gridApi = ref(null);
var gridOptions = null;

const columns = ref([
  {
    headerName: 'Name der Sportstätte',
    field: 'name'
  },
  {
    headerName: 'Adresse',
    field: 'address'
  },
  {
    headerName: 'Ortsteil',
    field: 'city'
  },
  {
    headerName: 'Verwaltender Verein ',
    field: 'club.displayName'
  }
]);

const defaultColDef = ref({
  editable: false,
  sortable: true,
  filter: true,
  flex: 1
});

// Read Facility data
console.log('Read facilities');

const colRef = query(
  collection(db, config.parameters.facilityDB),
  orderBy('name')
);
console.log(config.parameters.faciltiyDB);
onSnapshot(
  colRef,
  (snapshot) => {
    facilities.value = [];
    snapshot.docs.forEach((doc) => {
      facilities.value.push({ ...doc.data(), id: doc.id }) - 1;
    });
    dataReady.value = true;
    console.log('read facilities: ' + facilities.value.length + ' facilities');
  },
  (err) => {
    error.value = err;
  }
);

function onRowSelected(event) {
  console.log('row selected');
  console.log(event);
  if (event.api.getSelectedRows().length > 0) {
    selectedRow.value = event.api.getSelectedRows()[0];
    selectedFacilityData.value = selectedRow.value;
    selectedFacility.value = selectedRow.value.id;
  } else {
    selectedRow.value = '';
  }
}

// Edit Facility
const editFacility = (idOfSelectedFacility) => {
  selectedFacilityData.value = facilities.value.find(
    ({ id }) => id == idOfSelectedFacility
  );
  selectedFacility.value = selectedFacilityData.value.id;
};

// Create new Facility
const handleNewFacility = () => {
  console.log('Create new facility');

  if (!newFacility.value.name) {
    error.value = 'Welchen Namen hat die Sportstätte?';
  } else {
    if (!newFacility.value.city) {
      error.value =
        'Es fehlt die Angebe in welchem Ortsteil sich die Sportstätte befinden';
    } else {
      const docRef = addDoc(collection(db, config.parameters.facilityDB), {
        name: newFacility.value.name,
        address: newFacility.value.address,
        city: newFacility.value.city,
        club: user.club
      })
        .then(() => {
          audit.setAuditEntry(
            'Sportstätte angelegt: ' + newFacility.value.name,
            ''
          );
          selectedFacility.value = '';
          newFacility.value = {
            name: '',
            address: '',
            city: ''
          };
          error.value = '';
        })
        .catch((err) => {
          error.value = err;
        });
    }
  }
};

// Update facility data
const handleSubmit = () => {
  let docRef = doc(
    db,
    config.parameters.facilityDB,
    selectedFacilityData.value.id
  );
  console.log(user.club);

  updateDoc(docRef, {
    name: selectedFacilityData.value.name,
    address: selectedFacilityData.value.address,
    city: selectedFacilityData.value.city
  })
    .then(() => {
      audit.setAuditEntry(
        'Sportstätte aktualisiert: ' + selectedFacilityData.value.name,
        ''
      );
      showEditFacility.value = false;
      error.value = '';
    })
    .catch((err) => {
      error.value = err;
    });
};

// Google
const getAddressSearchResults = () => {
  console.log('getAddressSearchResults');
  clearTimeout(queryTimeout.value);
  queryTimeout.value = setTimeout(async () => {
    console.log('getAddressSearchResults setTimeout');
    var requestNearBySearch = {
      location: new google.maps.LatLng(51.65, 8.76),
      keyword: newFacility.value.name,
      radius: 8000
    };
    console.log(requestNearBySearch);
    var container = document.getElementById('results');
    var service = new google.maps.places.PlacesService(container);
    searchAddressResults.value = [];
    service.nearbySearch(requestNearBySearch, callbackNearBySeach);
  }, 1000);
};

function callbackNearBySeach(resultsNearBySearch, status) {
  if (status == google.maps.places.PlacesServiceStatus.OK) {
    if (resultsNearBySearch.length > 0) {
      dropdownIsActive.value = true;
      resultsNearBySearch.forEach((result) => {
        searchAddressResults.value.push({
          name: result.name,
          address: result.vicinity
        });
      });
    }
  }
}

function selectSearchResult(searchAddressResultItem) {
  newFacility.value.name = searchAddressResultItem.name;
  newFacility.value.address = searchAddressResultItem.address;
  dropdownIsActive.value = false;
  searchAddressResults.value = [];
  console.log(newFacility.value.name);
  console.log(newFacility.value.address);
}
</script>

<style scoped>
#my-table .table {
  background-color: whitesmoke;
}

.flex-container {
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
}

.wrap {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.flex-item {
  margin: 1em;
}

.dropdown {
  width: 100%;
}

.dropdown-trigger {
  width: 100%;
}
</style>
