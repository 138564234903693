<template>
  <div class="columns p-0 m-0 is-vcentered has-background-primary">
    <router-link class="column is-2 m-1" :to="{ name: 'Home' }">
      <div v-if="config.parameters.club.name === 'RWA'">
        <img class="image is-96x96" src="@/assets/rwa.gif" />
      </div>
      <div v-if="config.parameters.club.name === 'SCB'">
        <img class="image is-96x96" src="@/assets/scb.png" />
      </div>
    </router-link>
    <h1 class="column is-size-2 is-family-secondary has-text-white">
      {{ config.parameters.title }}
    </h1>
  </div>
  <nav
    :class="{ 'is-transformed': !showNavbar }"
    role="navigation "
    class="navbar has-shadow"
  >
    <div class="navbar-brand">
      <a
        :aria-expanded="isActive"
        :class="{ 'is-active': isActive }"
        role="button"
        class="navbar-burger has-text-white"
        aria-label="menu"
        data-target="collapse"
        @click="isActive = !isActive"
      >
        <span aria-hidden="true" />
        <span aria-hidden="true" />
        <span aria-hidden="true" />
      </a>
    </div>
    <div
      id="collapse"
      :class="{ 'is-active': isActive }"
      class="navbar-menu is-paddingless"
    >
      <div class="navbar-start">
        <div class="navbar-item p-0 m-0">
          <div class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-link is-size-5 has-text-white"> Informationen </a>
            <div class="navbar-dropdown">
              <router-link
                v-for="menuItem in config.parameters.Menu"
                :key="menuItem"
                class="navbar-item h"
                :to="{ name: 'Page', params: { id: menuItem } }"
              >
                {{ menuItem }}
              </router-link>
            </div>
          </div>
        </div>
        <div class="navbar-item p-0 m-0" v-if="user.user">
          <div
            class="navbar-item has-dropdown is-hoverable"
            v-if="user.role.name == 'admin' || user.role.name == 'editor'"
          >
            <a class="navbar-link is-size-5 has-text-white">
              Verwaltungsfunktionen
            </a>
            <div class="navbar-dropdown">
              <router-link
                v-if="user.role.name == 'admin'"
                class="navbar-item"
                :to="{ name: 'User' }"
              >
                Benutzer verwalten
              </router-link>
              <router-link
                v-if="user.role.name == 'admin' || user.role.name == 'editor'"
                class="navbar-item"
                :to="{ name: 'Faciliiy' }"
              >
                Sportstätten verwalten
              </router-link>
              <router-link
                v-if="user.role.name == 'admin' || user.role.name == 'editor'"
                class="navbar-item"
                :to="{ name: 'CourseCreate' }"
              >
                Sportangebot anlegen
              </router-link>
              <router-link
                v-if="user.role.name == 'admin' || user.role.name == 'editor'"
                class="navbar-item"
                :to="{ name: 'CourseList' }"
              >
                Übersicht der Sportangebote
              </router-link>
            </div>
          </div>
          <div v-if="!isActive" class="navbar-item p-0 m-0">
            <p class="control is-size-2 has-text-white">|</p>
          </div>
          <div v-if="!isActive" class="navbar-item">
            <div class="field is-grouped">
              <p class="control has-text-white">
                Hallo {{ user.firstname }} {{ user.lastname }}
              </p>
            </div>
          </div>
          <div v-if="!isActive" class="navbar-item p-0 m-0">
            <p class="control is-size-2 has-text-white">|</p>
          </div>
          <div v-if="!isActive" class="navbar-item">
            <p class="control is-size-7 has-text-white">
              Rolle: <br />
              {{ user.role.displayName }}
            </p>
          </div>
        </div>
      </div>

      <div class="navbar-end">
        <div class="navbar-item">
          <div class="buttons">
            <router-link
              class="button is-success"
              v-if="!user.isLoggedIn"
              :to="{ name: 'Signup' }"
            >
              Registrieren
            </router-link>
            <a
              class="button is-info"
              v-if="user.isLoggedIn"
              @click="handleLogout"
            >
              Abmelden
            </a>
            <router-link
              class="button is-info"
              v-if="!user.isLoggedIn"
              :to="{ name: 'Login' }"
            >
              Anmelden
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { useUserStore } from '@/stores/UserStore';
import { useRouter } from 'vue-router';
import { useConfiguraitonStore } from '@/stores/ConfigStore.js';
import throttle from 'lodash/throttle';

export default {
  data() {
    return {
      isActive: false,
      showNavbar: true,
      lastScrollPosition: 0
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', throttle(this.closeMenu, 500));
      window.addEventListener('scroll', throttle(this.hideNav, 250));
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.closeMenu);
    window.removeEventListener('scroll', this.hideNav);
  },
  methods: {
    closeMenu() {
      this.isActive = false;
    },
    hideNav() {
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollPosition < 0) return;
      if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60)
        return;
      this.showNavbar = currentScrollPosition < this.lastScrollPosition;
      this.lastScrollPosition = currentScrollPosition;
      setTimeout(this.closeMenu, 250);
    }
  },

  setup() {
    const router = useRouter();
    const user = useUserStore();
    const config = useConfiguraitonStore();

    // logout current user
    const handleLogout = () => {
      user.logout();
      router.push({ name: 'Home' });
    };
    return {
      user,
      config,
      handleLogout
    };
  }
};
</script>

<style>
.navbar {
  transition: transform 200ms ease-out;
}
</style>
