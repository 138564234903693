<template>
  <!-- Name des Angebotes -->
  <div class="field is-horizontal">
    <div class="field-label is-normal">
      <label class="label">Name des Sportangebotes:</label>
    </div>
    <div class="field-body">
      <div class="field">
        <p class="control is-expanded">
          <input
            class="input"
            type="text"
            placeholder="Name"
            v-model="course.name"
          />
        </p>
      </div>
    </div>
  </div>
  <!-- Beschreibung des Angebotes -->
  <div class="field is-horizontal">
    <div class="field-label is-normal">
      <label class="label">Beschreibung des Angebotes:</label>
    </div>
    <div class="field-body">
      <div class="field">
        <p class="control is-expanded">
          <QuillEditor
            style="min-height: 15em; background-color: white"
            :toolbar="QuillToolbar"
            v-model:content="course.description"
            contentType="html"
            theme="snow"
          />
        </p>
      </div>
    </div>
  </div>
  <!-- course schedule -->
  <div class="field is-horizontal">
    <div class="field-label is-normal">
      <label class="label">Planung des Angebotes:</label>
    </div>
    <div class="field-body has-background-white pb-4 pt-2">
      <div class="mx-4">
        <div class="columns is-multiline is-mobile">
          <div class="column is-3 has-text-weight-bold">
            <div class="mx-2">Tag</div>
          </div>
          <div class="column is-2 has-text-weight-bold">
            <div class="mx-2">Beginn</div>
          </div>
          <div class="column is-2 has-text-weight-bold">
            <div class="mx-2">Ende</div>
          </div>
          <div class="column is-5 has-text-weight-bold">
            <div class="mx-2">Hinweis</div>
          </div>
        </div>
        <div class="columns is-multiline is-mobile">
          <template v-for="day in course.schedule" :key="day.day">
            <div class="column is-3">
              <input
                class="mx-2"
                type="checkbox"
                v-model="day.active"
                id="days"
              />
              {{ day.day }}
            </div>
            <div class="column is-2">
              <input
                class="input mx-2"
                type="time"
                v-model="day.starttime"
                :disabled="!day.active"
              />
            </div>
            <div class="column is-2">
              <input
                class="input mx-2"
                type="time"
                v-model="day.endtime"
                :disabled="!day.active"
              />
            </div>
            <div class="column is-5">
              <input
                class="input mx-2"
                type="text"
                v-model="day.hint"
                :disabled="!day.active"
              />
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
  <!-- Ortsteil -->
  <div class="field is-horizontal">
    <div class="field-label is-normal">
      <label class="label">Veranstaltungsort:</label>
    </div>

    <div class="field-body">
      <div class="field checkboxGroup p-3">
        <div v-for="facility in facilities" :key="facility.id">
          <label class="checkbox">
            <input
              :id="facility"
              type="radio"
              :value="facility"
              v-model="course.facility"
            />
            {{ facility.name }}, {{ facility.address }} in
            {{ facility.city }}
          </label>
        </div>
        <p class="help is-gray">
          Weitere Veranstaltungsorte vorher mit der Menüfunktion "Sportstätten
          anlegen" anlegen
        </p>
      </div>
    </div>
  </div>
  <!-- Kontaktinformation -->
  <div class="field is-horizontal">
    <div class="field-label is-normal">
      <label class="label">Kontakt Information:</label>
    </div>
    <div class="field-body">
      <div class="field">
        <p class="control is-expanded">
          <input
            class="input"
            type="text"
            placeholder="Kontakt für dieses Angebot"
            v-model="course.contact"
          />
        </p>
        <p class="help is-gray">
          Telefonnummer und/oder E-Mail-Adresse bei dem ein interessierter
          Sportler sich melden kann
        </p>
      </div>
    </div>
  </div>
  <!-- Link für weitere Informationen -->
  <div class="field is-horizontal">
    <div class="field-label is-normal">
      <label class="label">Link:</label>
    </div>
    <div class="field-body">
      <div class="field">
        <p class="control is-expanded">
          <input
            class="input"
            type="text"
            placeholder="https://www.scborchen.de"
            v-model="course.link"
          />
        </p>
        <span class="help is-gray"
          >Bitte den kompletten Link angeben, inkl. http://... bzw.
          https.//...</span
        >

        <p class="help is-gray">
          Optionales Feld - Wird nur angezeigt, wenn ein Link zu weiteren
          Informationen zu diesem Angebot angegeben werden
        </p>
      </div>
    </div>
  </div>
  <!-- Farbauswahl -->
  <div class="field is-horizontal">
    <div class="field-label is-normal">
      <label class="label">Hintergrundfarbe:</label>
    </div>
    <div class="field-body">
      <div class="field">
        <p class="control is-expanded">
          <input class="input" type="color" v-model="course.color" />
        </p>
        <p class="help is-gray">
          Die Hintergrundfarbe wird für den Belegungsplan der Sportstätten
          verwendet
        </p>
      </div>
    </div>
  </div>
  <!-- Schlagwörter -->
  <div class="field is-horizontal">
    <div class="field-label is-normal">
      <label class="label">Schlagwörter für diese Sportangebot:</label>
    </div>
    <div class="field-body">
      <div class="field">
        <p class="control is-expanded">
          <input
            @keydown.enter.prevent="handleKeydown"
            class="input"
            type="text"
            placeholder="Schlagwort"
            v-model="tag"
          />
        </p>
        <span class="help is-gray"
          >Jedes neue Schlagwort mit der Auslösetaste bestätigen</span
        >
      </div>
    </div>
  </div>
  <div class="field is-horizontal">
    <div class="field-label is-normal">
      <label class="label">Schlagwörter für dieses Angebot:</label>
      <p class="is-size-7">(beliebig viele Schlagwörter mögich)</p>
    </div>
    <div class="field-body">
      <span v-for="tag in course.tags" :key="tag" class="tag m-1 is-success">
        {{ tag }}
        <button class="delete is-small" @click="handleTagDelete(tag)"></button>
      </span>
    </div>
  </div>
  <div class="field is-horizontal">
    <div class="field-label is-normal">
      <label class="label">Bisher genutzte Schlagwörter:</label>
      <p class="is-size-7">
        (Bitte möglichst bisherige Schlagwörter verwenden)
      </p>
    </div>
    <div class="field-body">
      <div class="content">
        <FilterNav
          :items="courseStore.filterOptionsTags"
          :currentFilters="[]"
          @filterChange="(tag) => course.tags.push(tag)"
        />
      </div>
    </div>
  </div>
  <div class="field is-horizontal">
    <div class="field-label is-normal">
      <label class="label">Diese Angebot ist intern:</label>
    </div>
    <div class="field-body">
      <div>
        <input type="checkbox" v-model="course.intern" />
      </div>
      <div class="is-size-7 mx-4">
        (Interne Angebote werden auf der Startseite nicht angezeigt)
      </div>
    </div>
  </div>
  <!-- Bild -->
  <div v-if="!course.image" class="field is-horizontal">
    <div class="field-label is-normal">
      <label class="label">Bild zum Sportangebot:</label>
    </div>
    <div class="field-body">
      <div v-if="course.name" class="field">
        <div class="columns">
          <div class="column">
            <p class="control is-expanded">
              <input class="input" type="file" @change="handleFile" />
            </p>
          </div>
          <div class="column">
            <p class="control is-expanded" v-if="uploadFile.file">
              <UploadMediafile :uploadMedia="uploadFile" :course="course" />
            </p>
            <div v-if="uploadFile.error">
              <div class="card-content">
                <article class="message is-danger">
                  <div class="message-body">
                    {{ uploadFile.error }}
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="field is-horizontal">
    <div class="field-label is-normal">
      <label class="label">Bild zum Sportangebot:</label>
    </div>
    <div class="field-body">
      <div class="field">
        <div class="card">
          <div class="card-image">
            <figure class="image">
              <img
                style="width:300px, hight:fit-content"
                :src="course.image"
                alt=""
              />
            </figure>
            <div class="card-contant is-overlay">
              <button
                @click="handleImageDelete"
                class="button is-large is-medium is-danger is-responsive m-1"
              >
                Löschen
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// vue imports
import { ref, toRef } from 'vue';

import { useConfiguraitonStore } from '@/stores/ConfigStore.js';
import { useFacilityStore } from '@/stores/FacilityStore.js';
import { useCourseStore } from '@/stores/CourseStore.js';
import UploadMediafile from '@/components/uploadMediafile.vue';
import FilterNav from '@/components/FilterNav.vue';
import { storage } from '@/firebase/config';
import { ref as storageRef, deleteObject } from 'firebase/storage';

export default {
  components: { UploadMediafile, FilterNav },
  name: 'formCourse',
  props: {
    uploadFile: Object,
    course: Object
  },
  setup(props) {
    const course = toRef(props, 'course');
    const uploadFile = toRef(props, 'uploadFile');

    const config = useConfiguraitonStore();
    const courseStore = useCourseStore();
    const tags = courseStore.filterOptionsTags;

    const days = ref(config.parameters.Days);
    const tag = ref('');

    if (!course.value.hasOwnProperty('schedule')) {
      course.value.schedule = [];
      if (course.value.hasOwnProperty('days')) {
        days.value.forEach((day) => {
          console.log('loop: ' + day);
          console.log(course.value.days);

          if (
            course.value.days.find((oldDay) => {
              if (oldDay === day) {
                if (!course.value.hint) {
                  course.value.hint = '';
                }
                course.value.schedule.push({
                  day: oldDay,
                  active: true,
                  starttime: course.value.starttime,
                  endtime: course.value.endtime,
                  hint: course.value.hint
                });
                return true;
              }
            })
          ) {
          } else {
            course.value.schedule.push({
              day: day,
              active: false,
              starttime: '',
              endtime: '',
              hint: ''
            });
          }
        });
      }
    }

    var QuillToolbar = [
      ['bold', 'italic', 'underline'], // toggled buttons
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      [{ header: [3, 4, 5, 6, false] }],
      [{ align: [] }],
      ['clean'] // remove formatting button
    ];
    uploadFile.value.mediaURL = '';
    uploadFile.value.mediaURL = '';
    uploadFile.value.error = '';

    // Read Facility data
    const facilityStore = useFacilityStore();
    const facilities = ref(facilityStore.facilities);

    const handleKeydown = () => {
      if (!course.value.tags.includes(tag.value)) {
        tag.value = tag.value.replace(/\s/g, ''); // remove all whitespace
        course.value.tags.push(tag.value);
      }
      tag.value = '';
    };

    const handleTagDelete = (toBeDeletedTag) => {
      for (var i = 0; i < course.value.tags.length; i++) {
        if (course.value.tags[i] == toBeDeletedTag) {
          course.value.tags.splice(i, 1);
        }
      }
    };

    const handleImageDelete = () => {
      console.log('Delete image: ' + course.value.image);
      const storageReference = storageRef(storage, course.value.image);
      // Delete the file
      deleteObject(storageReference)
        .then(() => {
          uploadFile.value.mediaURL = '';
          uploadFile.value.mediaURL = '';
          uploadFile.value.error = '';
          course.value.image = '';
        })
        .catch((err) => {
          console.log(err);
        });
    };

    // allowed file types
    const types = ['image/png', 'image/jpeg'];
    const handleFile = (e) => {
      let selected = e.target.files[0];
      if (selected && types.includes(selected.type)) {
        uploadFile.value.file = selected;
        uploadFile.value.mediaURL = '';
        uploadFile.value.error = '';
      } else {
        uploadFile.value.file = '';
        uploadFile.value.mediaURL = '';
        uploadFile.value.error = '';
      }
    };

    return {
      tag,
      facilities,
      days,
      uploadFile,
      QuillToolbar,
      courseStore,
      handleKeydown,
      handleTagDelete,
      handleImageDelete,
      handleFile
    };
  }
};
</script>

<style></style>
