<template>
  <!-- error message -->
  <article v-if="error" class="message is-danger">
    <div class="message-body">
      {{ error }}
    </div>
  </article>

  <div
    class="card mb-2 is-rounded fadeIn"
    :class="Math.random() < 0.5 ? 'a' : 'b'"
  >
    <header class="card-header is-rounded">
      <p class="card-header-title has-background-grey has-text-light">
        {{ course.name }}
      </p>
    </header>
    <div class="card-content">
      <div class="media">
        <div class="media-left">
          <figure v-if="course.image" class="image" style="width: 128px">
            <img :src="course.image" alt="{{ club }}" />
          </figure>
          <figure v-else class="image" style="width: 128px">
            <img class="image" src="@/assets/rwa.gif" />
          </figure>
        </div>
        <div class="media-content">
          <div
            class="content is-size-7"
            style="
              display: block;
              max-height: 150px;
              white-space: wrap;
              overflow: hidden;
              text-overflow: ellipsis;
            "
          >
            <span v-html="course.description"></span>
          </div>
          <span v-for="day in course.schedule" :key="day.day"
            ><span
              class="tag has-background-color-days has-text-dark m-1"
              v-if="day.active"
              >{{ day.day }}</span
            ></span
          >
        </div>
      </div>
    </div>
    <footer
      class="card-footer is-flex-wrap-wrap is-justify-content-center mt-3"
    >
      <span v-for="tag in course.tags" :key="tag">
        <span class="tag has-background-color-tags has-text-dark m-1">{{
          tag
        }}</span>
      </span>
    </footer>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useUserStore } from '@/stores/UserStore';
import { useRouter } from 'vue-router';

export default {
  props: {
    course: Object
  },

  setup(props) {
    // to visualize any error during from processing
    const error = ref(null);
    const router = useRouter();
    const user = useUserStore();

    return { error, user };
  }
};
</script>

<style>
div.a {
  animation: fadeIn 2s;
}

div.b {
  animation: fadeIn 4s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
