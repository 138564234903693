<template>
  <div class="columns">
    <div class="column"></div>
    <div class="column is-four-fifths">
      <article class="message box p-0 is-primary my-6 has-shadow">
        <p class="message-header">Anmelden</p>
        <div class="block p-4">
          <form class="message is-primary">
            <div class="block">Bitte geben sie die Daten ein, die sie bei der Registrierung verwendet haben</div>
            <!-- email -->
            <div class="field">
              <label class="label">E-Mail Adresse </label>
              <div class="control">
                <input class="input is-normal" type="email" placeholder="email" v-model="email" />
              </div>
            </div>
            <!-- password -->
            <div class="field">
              <label class="label">Passwort </label>
              <div class="control">
                <input class="input" type="password" placeholder="password" v-model="password" />
              </div>
            </div>
            <!-- error message -->
            <article v-if="error" class="message is-danger">
              <div class="message-body">
                {{ error }}
              </div>
            </article>
            <!-- Submit  -->
            <div class="field is-grouped is-grouped-centered">
              <p class="control">
                <a class="button is-primary" @click="handleSubmit"> Anmelden </a>
              </p>
            </div>
          </form>
        </div>
      </article>
    </div>
    <div class="column"></div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useUserStore } from '@/stores/UserStore';
import { useRouter } from 'vue-router';
import { useAuditStore } from '@/stores/AuditStore';

export default {
  setup() {
    // to visualize any error during from processing
    const error = ref(null);
    // data field
    const email = ref('');
    const password = ref('');
    // user store
    const user = useUserStore();
    const audit = useAuditStore();

    // activate router
    const router = useRouter();

    const handleSubmit = () => {
      // call store to manage login
      const data = {
        email: email.value,
        password: password.value
      };
      user
        .login(data)
        .then(() => {
          error.value = '';
          audit.setAuditEntry('Login: ' + email.value, '');
          router.push({ name: 'Home' });
        })
        .catch((err) => {
          console.log('Error: ' + err.code);
          switch (err.code) {
            case 'auth/invalid-email':
              error.value = 'Ungültige E-Mail Adresse';
              break;
            case 'auth/user-not-found':
              error.value = 'Es wurde keine Benutzer mit dieser E-Mail Adresse gefunden';
              break;
            case 'auth/wrong-password':
              error.value = 'Falesches Passwort';
              break;
            default:
              error.value = 'E-Mail oder Passwort ist nicht korrekt';
              break;
          }
        });
    };

    return {
      handleSubmit,
      email,
      password,
      error
    };
  }
};
</script>

<style>

</style>