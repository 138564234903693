import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { useConfiguraitonStore } from '@/stores/ConfigStore.js';
import { useAuditStore } from '@/stores/AuditStore';
import { db } from '@/firebase/config';
import {
  collection,
  query,
  orderBy,
  where,
  onSnapshot
} from 'firebase/firestore';

export const useCourseStore = defineStore('course', () => {
  let courses = [];
  const loading = ref(true);
  const filterOptionsTags = ref([]);
  const filterOptionsDays = ref([]);
  const filterOptionsCities = ref([]);
  const currentTagFilters = ref([]);
  const currentDayFilters = ref([]);
  const currentCityFilters = ref([]);
  const filteredCourses = ref([]);
  const config = useConfiguraitonStore();
  const audit = useAuditStore();

  const q = query(
    collection(db, config.parameters.courseDB),
    where('club.name', '==', config.parameters.club.name),
    orderBy('order')
  );
  console.log('read program for ' + config.parameters.club.name);
  onSnapshot(
    q,
    (snapshot) => {
      courses = [];
      let order = 0;
      snapshot.docs.forEach((doc) => {
        let tmpData = doc.data();
        if (!tmpData.hasOwnProperty('color')) {
          tmpData.color =
            config.parameters.colorDefault[config.parameters.club.name];
        }
        if (!tmpData.hasOwnProperty('order')) {
          tmpData.order = order++;
        }
        if (!tmpData.hasOwnProperty('intern')) {
          tmpData.intern = false;
        }
        courses.push({ ...tmpData, id: doc.id });
        console.log('read: ') + tmpData.name;
      });
      updateAvailableFilter(courses);
      updateFilteredDocuments();
      loading.value = false;
      console.log('CourseStore initialized');
    },
    (err) => {
      console.log(err);
    }
  );

  // getters
  const getCoursesByCurrentFilter = computed(() => {
    console.log(getCoursesByCurrentFilter);
  });

  function getCoursesByDay(searchDay, facilityId) {
    if (loading.value) console.log('----> to early');
    var coursesFound = [];
    courses.forEach((course) => {
      if (course.facility.id === facilityId) {
        if (course.hasOwnProperty('schedule')) {
          course.schedule.forEach((courseDay) => {
            if (courseDay.active) {
              if (courseDay.day === searchDay) {
                coursesFound.push(course);
              }
            }
          });
        }
      }
    });
    return coursesFound;
  }

  function getCourseByDayTime(searchDay, time) {
    var coursesFound = [];
    courses.forEach((course) => {
      if (course.hasOwnProperty('schedule')) {
        course.schedule.forEach((courseDay) => {
          if (courseDay.active) {
            if (courseDay.day === searchDay) {
              if (time >= courseDay.starttime && time < courseDay.endtime) {
                coursesFound.push(course);
              }
            }
          }
        });
      }
    });
    return coursesFound;
  }

  // Actions
  function setDayFilter(e) {
    var index = currentDayFilters.value.indexOf(e);
    if (currentDayFilters.value.indexOf(e) < 0) {
      currentDayFilters.value.push(e);
    } else {
      currentDayFilters.value.splice(index, 1);
    }
    updateFilteredDocuments();
  }

  function updateAvailableFilter(toScanCourses) {
    // scan for filter options
    filterOptionsTags.value = [];
    filterOptionsDays.value = [];
    var usedDays = [];
    filterOptionsCities.value = [];
    toScanCourses.forEach((course) => {
      course.tags.forEach((tag) => {
        if (!filterOptionsTags.value.includes(tag)) {
          filterOptionsTags.value.push(tag);
        }
      });
      if (course.hasOwnProperty('schedule')) {
        course.schedule.forEach((day) => {
          if (day.active) {
            if (!usedDays.includes(day.day)) {
              usedDays.push(day.day);
            }
          }
        });
      }
      if (course.facility) {
        if (!filterOptionsCities.value.includes(course.facility.city)) {
          filterOptionsCities.value.push(course.facility.city);
        }
      }
    });
    // sort by day of the week
    config.parameters.Days.forEach((day) => {
      if (usedDays.includes(day)) {
        filterOptionsDays.value.push(day);
      }
    });
    // sort alphabetic
    filterOptionsTags.value = filterOptionsTags.value.sort((p1, p2) =>
      p1 > p2 ? 1 : p1 < p2 ? -1 : 0
    );
  }

  function setTagFilter(e) {
    var index = currentTagFilters.value.indexOf(e);
    if (currentTagFilters.value.indexOf(e) < 0) {
      currentTagFilters.value.push(e);
    } else {
      currentTagFilters.value.splice(index, 1);
    }
    updateFilteredDocuments();
  }

  function setCityFilter(e) {
    var index = currentCityFilters.value.indexOf(e);
    if (currentCityFilters.value.indexOf(e) < 0) {
      currentCityFilters.value.push(e);
    } else {
      currentCityFilters.value.splice(index, 1);
    }
    updateFilteredDocuments();
  }

  function updateFilteredDocuments() {
    filteredCourses.value = [];
    // audit.setAuditEntry("Filter: " + currentTagFilters.value + " " + currentDayFilters.value + " " + currentCityFilters.value, "");
    courses.forEach((course) => {
      let tagMatch = false;
      let tagCount = 0;
      if (currentTagFilters.value.length > 0) {
        currentTagFilters.value.forEach((tag) => {
          if (course.tags.indexOf(tag) >= 0) {
            tagCount++;
          }
        });
        if (currentTagFilters.value.length == tagCount) tagMatch = true;
      } else {
        tagMatch = true;
      }
      let dayMatch = false;
      let dayCount = 0;
      if (currentDayFilters.value.length > 0) {
        currentDayFilters.value.forEach((day) => {
          course.schedule.forEach((courseSchedule) => {
            if (courseSchedule.day === day) {
              if (courseSchedule.active) {
                dayCount++;
              }
            }
          });
        });
        if (currentDayFilters.value.length == dayCount) dayMatch = true;
      } else {
        dayMatch = true;
      }
      let cityMatch = false;
      if (currentCityFilters.value.length > 0) {
        if (currentCityFilters.value.indexOf(course.facility.city) >= 0) {
          cityMatch = true;
        }
      } else {
        cityMatch = true;
      }

      if (cityMatch && tagMatch && dayMatch) {
        filteredCourses.value.push(course);
      }
    });
    updateAvailableFilter(filteredCourses.value);
  }

  return {
    filteredCourses,
    getCoursesByCurrentFilter,
    getCoursesByDay,
    getCourseByDayTime,
    filterOptionsTags,
    filterOptionsDays,
    filterOptionsCities,
    currentTagFilters,
    currentDayFilters,
    currentCityFilters,
    setDayFilter,
    setTagFilter,
    setCityFilter,
    loading
  };
});
