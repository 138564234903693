<template>
  <span
    v-for="item in items"
    :key="item"
    class="tag is-clickable is-small m-1"
    @click="updateFilter(item)"
    :class="
      currentFilters.includes(item) ? 'is-success' : 'is-success is-light'
    "
  >
    {{ item }}
  </span>
</template>

<script>
export default {
  props: ['items', 'currentFilters'],
  emits: ['filterChange'],

  methods: {
    updateFilter(by) {
      if (this.currentFilter === by) {
        this.$emit('filterChange', '');
      } else {
        this.$emit('filterChange', by);
      }
    }
  },
  setup() {}
};
</script>

<style>
</style>