<template>
  <div class="block mx-4 mt-4">
    <article class="message p-0 is-primary has-shadow">
      <p class="message-header">Aktivitäten</p>
      <div class="block p-4">
        <section id="my-table">
          <div class="table-container">
            <table class="table is-striped is-fullwidth">
              <thead>
                <tr>
                  <th>Benutzer</th>
                  <th>Datum</th>
                  <th>Aktion</th>
                  <th>Verein</th>
                </tr>
              </thead>
              <tbody>
                <!-- Liste -->
                <tr v-for="audit in auditList" :key="audit.time">
                  <td>{{ audit.name }}</td>
                  <td>
                    {{ audit.time.toDate().toLocaleString('de-DE') }}
                  </td>
                  <td>{{ audit.content }}</td>
                  <td>
                    <span v-if="audit.club">{{ audit.club }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
        <div class="block">
          <nav
            class="pagination my-3"
            role="navigation"
            aria-label="pagination"
          >
            <a
              class="pagination-previous button"
              @click="previousBatch()"
              :class="currentCurser == 0 ? 'is-disabled' : 'is-info'"
              >Vorherige Seite</a
            >
            <a class="pagination-next button is-info" @click="nextBatch()"
              >Nächste Seite</a
            >
          </nav>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
// vue imports
import { ref } from 'vue';

// firebase imports
import { db } from '@/firebase/config';
import {
  collection,
  getDocs,
  query,
  orderBy,
  limit,
  startAfter
} from 'firebase/firestore';
import { useConfiguraitonStore } from '@/stores/ConfigStore.js';

export default {
  setup() {
    // to visualize any error during from processing
    const error = ref(null);
    // get store
    const config = useConfiguraitonStore();
    const currentCurser = ref(0);
    let listOfQueryCursers = [];
    const batchSize = 20;
    const auditList = ref([]);

    // collection ref for users
    const colRef = query(collection(db, config.parameters.auditDB));
    // get first bach
    getBatch();

    function nextBatch() {
      currentCurser.value++;
      getBatch();
    }
    function previousBatch() {
      if (currentCurser.value > 0) {
        currentCurser.value--;
        getBatch();
      }
    }

    function getBatch() {
      // Read user data
      auditList.value = [];
      let q;
      if (currentCurser.value == 0) {
        q = query(colRef, orderBy('time', 'desc'), limit(batchSize));
      } else {
        q = query(
          colRef,
          orderBy('time', 'desc'),
          limit(batchSize),
          startAfter(listOfQueryCursers[currentCurser.value - 1])
        );
      }

      getDocs(q)
        .then((snapshot) => {
          snapshot.docs.forEach((doc) => {
            auditList.value.push({ ...doc.data() });
          });
          listOfQueryCursers[currentCurser.value] =
            snapshot.docs[snapshot.docs.length - 1];
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
    return {
      error,
      auditList,
      currentCurser,
      nextBatch,
      previousBatch
    };
  }
};
</script>

<style scoped>
#my-table .table {
  background-color: whitesmoke;
}

.checkboxGroup {
  max-height: 30em;
  overflow: auto;
  display: block;
  border-style: solid;
  border-color: #4f515a;
}
</style>
