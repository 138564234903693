<template>
  <div class="block mx-4 mt-4">
    <article class="message box p-0 is-primary has-shadow">
      <div class="message-header">
        <p>Sportangebot bearbeiten</p>
      </div>
      <div v-if="course" class="block p-4">
        <!-- error message -->
        <article v-if="error" class="message is-danger">
          <div class="message-body">
            {{ error }}
          </div>
        </article>

        <!-- Form to enter / edit course data -->
        <FormCourse v-if="!loading" :course="course" :uploadFile="uploadFile" />
      </div>
      <div class="box is-centered">
        <a class="button is-info is-fullwidth" @click="handleSubmit"
          >Speichern</a
        >
      </div>
    </article>
  </div>
</template>

<script>
// vue imports
import { ref } from 'vue';
import { useRouter } from 'vue-router';

// firebase imports
import { db } from '@/firebase/config';
import {
  collection,
  doc,
  getDoc,
  updateDoc,
  serverTimestamp
} from 'firebase/firestore';
import { useConfiguraitonStore } from '@/stores/ConfigStore.js';
import { useUserStore } from '@/stores/UserStore';
import { useAuditStore } from '@/stores/AuditStore';
import UploadMediafile from '@/components/uploadMediafile.vue';
import FormCourse from '@/components/formCourse.vue';

export default {
  components: { UploadMediafile, FormCourse },
  props: ['id'],
  setup(props) {
    const error = ref();
    const router = useRouter();
    const loading = ref(true);

    // get Stores
    const config = useConfiguraitonStore();
    const user = useUserStore();
    const audit = useAuditStore();

    const course = ref({
      name: '',
      description: ''
    });
    const uploadFile = ref({ mediaURL: '', file: '', error: '' });

    const docRef = doc(db, config.parameters.courseDB, props.id);
    getDoc(docRef)
      .then((snapshot) => {
        course.value = { ...snapshot.data() };
        if (course.value.color === '' || course.value.color === undefined) {
          console.log('set color');
          course.value.color =
            config.parameters.colorDefault[config.parameters.club.name];
        }
        if (!'intern' in course.value) {
          course.value.inter = false;
        }
        loading.value = false;
      })
      .catch((err) => {
        error.value = err;
      });

    const handleSubmit = () => {
      error.value = '';
      if (!course.value.name) {
        console.log('name');
        error.value = 'Jedes Angebot benötigt einen Namen';
      } else if (!course.value.description) {
        console.log('description');
        error.value = 'Jedes Angebot benötigt eine Beschreibung';
      } else if (!course.value.contact) {
        console.log('contact');
        error.value = 'Jedes Angebot benötigt eine Kontaktangabe';
      } else if (course.value.tags.length == 0) {
        console.log('tags: ' + course.value.tags.length);
        error.value = 'Jedes Angebot benötigt mindestens ein Schlagwort';
      } else if (uploadFile.value.error) {
        error.value = uploadFile.value.error;
      }
      if (!error.value) {
        if (uploadFile.value.mediaURL) {
          course.value.image = uploadFile.value.mediaURL;
        }
        course.value.modifiedByID = user.firebaseUserID;
        course.value.modifiedBy = user.firstname + ' ' + user.lastname;
        course.value.modiefiedAt = serverTimestamp();
        console.log(course.value);
        updateDoc(docRef, course.value)
          .then(() => {
            error.value = '';
            audit.setAuditEntry(
              'Sportangebot aktualisiert: ' + course.value.name,
              course.value.club.name
            );
            router.go(-1);
          })
          .catch((err) => {
            error.value = err;
          });
      } else {
        console.log(error.value);
      }
    };
    return {
      error,
      loading,
      course,
      config,
      confirm,
      uploadFile,
      handleSubmit
    };
  }
};
</script>

<style scoped>
.checkboxGroup {
  max-height: 30em;
  overflow: auto;
  display: block;
  border-style: solid;
  border-color: #4f515a;
  background-color: white;
}
</style>
