<template>
  <div class="block mx-4 mt-4">
    <article class="message box p-0 is-primary has-shadow">
      <p class="message-header">Liste der Sportangebote</p>
      <div class="block p-4">
        <ag-grid-vue
          class="ag-theme-alpine"
          style="width: 100%; height: 700px"
          :columnDefs="columns"
          :rowData="courseStore.filteredCourses"
          :gridOptions="gridOptions"
          :defaultColDef="defaultColDef"
          :rowDragManaged="true"
          rowSelection="single"
          animation="true"
          @grid-ready="onGridReady"
          @dragStopped="dragStopped"
          @row-selected="onRowSelected"
        ></ag-grid-vue>
      </div>
      <!-- error message -->
      <article v-if="error" class="message is-danger">
        <div class="message-body">
          {{ error }}
        </div>
      </article>
      <!-- action bar -->
      <div class="block px-4 mx-4">
        <div
          class="columns is-4 px-4 has-background-white mb-4"
          style="
            height: 4rem;
            border-style: solid;
            border-color: #babfc7;
            border-width: 1px;
          "
        >
          <div
            v-if="selectedRow"
            class="column is-three-fifths has-background-light m-2"
          >
            <span class="is-size-6"> {{ selectedRow.name }}</span>
          </div>
          <div class="column">
            <router-link
              v-if="selectedRow"
              :to="{ name: 'CourseDetails', params: { id: selectedRow.id } }"
            >
              <button class="button is-success">Anschauen</button>
            </router-link>
          </div>
          <div class="column">
            <router-link
              v-if="selectedRow"
              :to="{ name: 'CourseEdit', params: { id: selectedRow.id } }"
            >
              <button class="button is-warning">Bearbeiten</button>
            </router-link>
          </div>
          <div class="column">
            <button
              v-if="selectedRow"
              class="button is-danger"
              @click="confirmDelete = selectedRow"
            >
              Löschen
            </button>
          </div>
        </div>
        <br />
      </div>
    </article>
  </div>
  <div class="modal is-active" v-if="confirmDelete">
    <div class="modal-background"></div>
    <div class="modal-content">
      <article class="message is-danger">
        <div class="message-header">
          <p>Bist du sicher?</p>
          <button class="delete" @click="confirmDelete = ''"></button>
        </div>
        <div class="message-body">
          <div class="block">
            Soll das Angebot
            <span class="has-text-weight-semibold"
              >{{ selectedRow.name }}
            </span>
            wirklich gelöscht werden?
          </div>
          <div class="block">
            Das gelöschte Angebot kann nicht wiederhergestellt werden!
          </div>
          <button class="button is-danger" @click="handleDelete(selectedRow)">
            Löschen
          </button>
        </div>
      </article>
    </div>
  </div>
</template>
<script setup>
// Grid imports
import { AgGridVue } from 'ag-grid-vue3';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import RenderData from '@/components/RenderData.vue';
// vue imports
import { ref } from 'vue';
// Store importes
import { useCourseStore } from '@/stores/CourseStore.js';
import { useConfiguraitonStore } from '@/stores/ConfigStore.js';
// firebase imports
import { db } from '@/firebase/config';
import { doc, writeBatch, deleteDoc } from 'firebase/firestore';
import { ref as storageRef, deleteObject } from 'firebase/storage';
import { storage } from '@/firebase/config';

const courseStore = useCourseStore();
const config = useConfiguraitonStore();

// Grid definitions
const selectedRow = ref(null);
var gridOptions = null;

const columns = ref([
  {
    colId: 'name',
    headerName: 'Angebot',
    field: 'name',
    sortable: true,
    filter: true,
    rowDrag: true,
    minWidth: 250
  },
  {
    headerName: 'Angelegt',
    field: 'createdAt',
    sortable: true,
    cellRenderer: RenderData,
    cellRendererParams: {
      type: 'date',
      instruction: 'createdBy'
    }
  },
  {
    headerName: 'Anderungssdatum',
    field: 'modiefiedAt',
    sortable: true,
    cellRenderer: RenderData,
    cellRendererParams: {
      type: 'date',
      instruction: 'modifiedBy'
    }
  },
  {
    headerName: 'Aufrufe',
    field: 'views',
    sortable: true,
    minWidth: 50,
    maxWidth: 100
  },
  {
    headerName: 'Intern',
    field: 'intern',
    minWidth: 50,
    maxWidth: 100
  },
  {
    headerName: 'Letzter Aufruf',
    field: 'lastView',
    sortable: true,
    cellRenderer: RenderData,
    cellRendererParams: {
      type: 'date',
      instruction: 'none'
    },
    minWidth: 50,
    maxWidth: 150
  },
  {
    headerName: 'Ort',
    field: 'facility.name',
    sortable: true,
    filter: true
  },
  {
    headerName: 'Zeiten',
    field: 'schedule',
    cellRenderer: RenderData,
    cellRendererParams: {
      type: 'schedule'
    },
    minWidth: 250
  },
  {
    headerName: 'Farbe',
    field: 'color',
    sortable: true,
    cellRenderer: RenderData,
    cellRendererParams: {
      type: 'color'
    }
  }
]);

const defaultColDef = ref({
  editable: false,
  sortable: false,
  filter: false,
  autoHeight: true,
  flex: 1
});

function onGridReady(params) {
  console.log('onReady');
}

function onRowSelected(event) {
  console.log('row selected');
  console.log(event);
  if (event.api.getSelectedRows().length > 0) {
    selectedRow.value = event.api.getSelectedRows()[0];
  } else {
    selectedRow.value = '';
  }
}

function dragStopped(params) {
  let orderChanged = false;
  console.log('dragStopped');
  // Check if order is changed
  params.api.forEachNode(function (node, index) {
    if (node.data.order != index) {
      orderChanged = true;
      node.data.order = index;
    }
  });
  // update changed order at firestore
  console.log('orderChange: ' + orderChanged);
  if (orderChanged) {
    const batch = writeBatch(db);
    params.api.forEachNode(function (node, index) {
      const sfRef = doc(db, config.parameters.courseDB, node.data.id);
      batch.update(sfRef, { order: node.data.order });
    });
    batch
      .commit()
      .then(() => {
        console.log('order updated');
      })
      .catch((err) => {
        console.log(err);
      });
  }
}

// to visualize any error during from processing
const error = ref(null);

const confirmDelete = ref('');

const handleDelete = (selectedCourse) => {
  // delete Image
  console.log('Delete image: ' + selectedCourse.image);
  if (selectedCourse.image) {
    const storageReference = storageRef(storage, selectedCourse.image);
    // Delete the file
    deleteObject(storageReference)
      .then(() => {
        selectedCourse.image = '';
      })
      .catch((err) => {
        error.value = err;
      });
  }
  // delete course record
  console.log('Delete course: ', selectedCourse.id);
  let docRef = doc(db, config.parameters.courseDB, selectedCourse.id);
  deleteDoc(docRef)
    .then(() => {
      console.log('Course deleted ');
    })
    .catch((err) => {
      error.value = err;
    });
  // reset modal and selected row
  confirmDelete.value = '';
  selectedRow.value = '';
};
</script>
<style></style>
