<template>
  <div
    v-if="!facilityStore.loading && !courseStore.loading"
    class="content p-2"
  >
    <h1>Belegungsplan {{ facilityStore.getName(id) }}</h1>

    <div class="table-container">
      <table class="table">
        <thead>
          <tr>
            <th style="width: 2%; height: 3em">Uhrzeit</th>
            <template
              v-for="(day, index) in config.parameters.Days"
              :key="day"
              class="column"
            >
              <th style="width: 14%; text-align: center">
                <router-link :to="{ name: 'CourseCreate' }">
                  {{ day }}
                </router-link>
              </th>
            </template>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="m-0 p-0">
              <CalendarElementTime :timestamps="timestamps" />
            </td>
            <template
              v-for="(day, index) in config.parameters.Days"
              :key="index"
              class="column"
            >
              <td class="m-0 p-0">
                <CalendarElementDay
                  :courseList="courseStore.getCoursesByDay(day, id)"
                  :day="day"
                  :indexDay="index"
                  :timestamps="timestamps"
                  :startOfDay="startOfDay"
                  :raster="raster"
                  :rasterHightInEm="rasterHightInEm"
                />
              </td>
            </template>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useFacilityStore } from '@/stores/FacilityStore';
import { useConfiguraitonStore } from '@/stores/ConfigStore';
import { useCourseStore } from '@/stores/CourseStore';
import CalendarElementTime from '@/components/CalendarElementTime.vue';
import CalendarElementDay from '@/components/CalendarElementDay.vue';
import moment from 'moment';
moment.locale('de');

const props = defineProps({
  id: String
});

const facilityStore = useFacilityStore();
const config = useConfiguraitonStore();
const courseStore = useCourseStore();

const startOfDay = ref('13:00');
const endOfDay = ref('22:00');
const raster = ref(30);
const rasterHightInEm = ref(3);

const start = new Date('2023-01-01T' + startOfDay.value);
const end = new Date('2023-01-01T' + endOfDay.value);
const timestamps = ref([]);
while (start <= end) {
  timestamps.value.push(start.setMinutes(start.getMinutes()));
  start.setMinutes(start.getMinutes() + raster.value);
}
</script>

<style lang="scss" scoped></style>
