<template>
  <div v-if="!config.loading" class="columns">
    <div class="column">
      <div class="card m-3">
        <header class="card-header has-background-color-tags">
          <p class="card-header-title has-text-dark">Filter nach Interessen</p>
        </header>
        <div class="card-content">
          <div class="content">
            <FilterNav
              :items="courseStore.filterOptionsTags"
              :currentFilters="courseStore.currentTagFilters"
              @filterChange="(tag) => courseStore.setTagFilter(tag)"
            />
          </div>
        </div>
      </div>
      <div class="card m-3">
        <header class="card-header has-background-color-days">
          <p class="card-header-title has-text-dark">
            Filter nach Veranstaltungstagen
          </p>
        </header>
        <div class="card-content">
          <div class="content">
            <FilterNav
              :items="courseStore.filterOptionsDays"
              :currentFilters="courseStore.currentDayFilters"
              @filterChange="(day) => courseStore.setDayFilter(day)"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="column m-3 is-three-quarters">
      <div class="message-header is-family-secondary is-size-5 mb-3">
        Sportangebote des {{ config.parameters.club.displayName }}
      </div>
      <div v-if="courseStore.filteredCourses">
        <ListView :courses="courseStore.filteredCourses" />
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from 'vue';

import ListView from '@/components/ListView.vue';
import FilterNav from '@/components/FilterNav.vue';
import { useConfiguraitonStore } from '@/stores/ConfigStore.js';
import { useCourseStore } from '@/stores/CourseStore.js';

export default {
  components: { ListView, FilterNav },

  setup() {
    const config = useConfiguraitonStore();

    const error = ref('');

    // setup course store
    const courseStore = useCourseStore();
    const days = courseStore.filterOptionsDays;
    const tags = courseStore.filterOptionsTags;
    const cities = courseStore.filterOptionsCities;
    const dayList = ref([]);

    return {
      error,
      courseStore,
      config,
      days,
      tags,
      cities,
      dayList
    };
  }
};
</script>

<style></style>
