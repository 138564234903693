<template>
  <div v-if="props.params.type == 'date'">
    <div v-if="props.params.instruction == 'none'">
      {{ formatDate(props.params.getValue()) }}
    </div>
    <div v-else>
      <div style="line-height: normal">
        {{ formatDate(props.params.getValue()) }}
        <span v-if="props.params.instruction == 'createdBy'">
          von<br />{{ props.params.data.createdBy }}
        </span>
        <span v-if="props.params.instruction == 'modifiedBy'">
          von<br />{{ props.params.data.createdBy }}
        </span>
      </div>
    </div>
  </div>
  <div v-else-if="props.params.type == 'schedule'">
    <div v-for="day in props.params.getValue()" :key="day.day">
      <div v-if="day.active" style="line-height: normal">
        {{ day.starttime }}-{{ day.endtime }}
        <span v-if="day.hint"> - {{ day.hint }}</span>
      </div>
    </div>
  </div>
  <div v-else-if="props.params.type == 'name'">
    <div>
      {{ props.params.data.firstname }} {{ props.params.data.lastname }}
    </div>
  </div>
  <div v-else-if="props.params.type == 'color'">
    <span
      class="tag is-rounded-"
      :style="'background-color:' + props.params.getValue()"
    ></span>
  </div>
</template>
<script setup>
import { ref } from 'vue';

const props = defineProps({
  params: Object
});

const courseId = ref(props.params.data.id);

function formatDate(date) {
  if (date == undefined) {
    return '';
  } else {
    return date.toDate().toLocaleDateString('de-DE');
  }
}
</script>

<style lang="scss" scoped></style>
