import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCS6Ix06nmwcshrUUD6drEhZUmYgCeAJlU',
  authDomain: 'svrwalfen.firebaseapp.com',
  projectId: 'svrwalfen',
  storageBucket: 'svrwalfen.appspot.com',
  messagingSenderId: '863119505868',
  appId: '1:863119505868:web:66573ae03e4e960d0e4569'
};
// init firebase
const app = initializeApp(firebaseConfig);

// init firebase db and auth
const db = getFirestore();
const auth = getAuth();
const storage = getStorage(app);

export { db, auth, storage };
